import React from "react";
import styled, { useTheme } from "styled-components";
import { breakpoints } from "../../components/base/breakpoints";

function Teamwork() {
  const theme = useTheme();

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
    >
      <path
        d="M49.9249 19.9245C50.2257 17.8032 50.9776 15.682 52.331 14.0154C53.8347 11.8942 55.94 10.6821 58.6468 10.379C58.9475 10.379 59.3986 10.379 59.6994 10.379C57.5941 10.379 55.7896 9.77297 54.1355 8.40933C53.0828 7.50025 52.1806 6.43964 51.5791 5.07601C50.8272 3.56086 50.3761 2.04572 50.0753 0.379054C50.0753 0.379054 50.0753 0.379054 50.0753 0.227539C55.4889 0.227539 60.0001 4.77298 60.0001 10.2275C60.0001 15.682 55.6392 20.2275 50.0753 20.2275C49.7746 20.076 49.7746 20.076 49.9249 19.9245Z"
        fill={theme.texto}
      />
      <path
        d="M49.7744 0.227539C49.7744 0.379054 49.7744 0.682083 49.624 0.833598C49.1729 2.80329 48.5714 4.62147 47.5188 6.28813C45.8647 8.56085 43.609 9.92448 40.7519 10.2275C40.4511 10.2275 40.3007 10.2275 40 10.2275C41.6541 10.2275 43.3082 10.6821 44.6616 11.5911C46.1654 12.5002 47.218 13.8639 48.1203 15.379C48.8722 16.8942 49.4737 18.4093 49.624 20.076C49.624 20.076 49.624 20.076 49.624 20.2275C44.2105 20.2275 39.6992 15.682 39.6992 10.2275C39.8496 4.62147 44.3609 0.227539 49.7744 0.227539Z"
        fill={theme.texto}
      />
      <path
        d="M49.9249 39.9245C50.2257 37.8032 50.9776 35.682 52.331 34.0154C53.8347 31.8942 55.94 30.6821 58.6468 30.379C58.9475 30.379 59.3986 30.379 59.6994 30.379C57.5941 30.379 55.7896 29.773 54.1355 28.4093C53.0828 27.5002 52.1806 26.4396 51.5791 25.076C50.8272 23.5609 50.3761 22.0457 50.0753 20.3791C50.0753 20.3791 50.0753 20.3791 50.0753 20.2275C55.4889 20.2275 60.0001 24.773 60.0001 30.2275C60.0001 35.682 55.6392 40.2275 50.0753 40.2275C49.7746 40.076 49.7746 40.076 49.9249 39.9245Z"
        fill={theme.texto}
      />
      <path
        d="M49.7744 20.2275C49.7744 20.3791 49.7744 20.6821 49.624 20.8336C49.1729 22.8033 48.5714 24.6215 47.5188 26.2881C45.8647 28.5608 43.609 29.9245 40.7519 30.2275C40.4511 30.2275 40.3007 30.2275 40 30.2275C41.6541 30.2275 43.3082 30.6821 44.6616 31.5911C46.1654 32.5002 47.218 33.8639 48.1203 35.379C48.8722 36.8942 49.4737 38.4093 49.624 40.076C49.624 40.076 49.624 40.076 49.624 40.2275C44.2105 40.2275 39.6992 35.682 39.6992 30.2275C39.8496 24.6215 44.3609 20.2275 49.7744 20.2275Z"
        fill={theme.texto}
      />
      <path
        d="M49.9249 59.9245C50.2257 57.8032 50.9776 55.682 52.331 54.0154C53.8347 51.8942 55.94 50.6821 58.6468 50.379C58.9475 50.379 59.3986 50.379 59.6994 50.379C57.5941 50.379 55.7896 49.773 54.1355 48.4093C53.0828 47.5002 52.1806 46.4396 51.5791 45.076C50.8272 43.5609 50.3761 42.0457 50.0753 40.3791C50.0753 40.3791 50.0753 40.3791 50.0753 40.2275C55.4889 40.2275 60.0001 44.773 60.0001 50.2275C60.0001 55.682 55.6392 60.2275 50.0753 60.2275C49.7746 60.076 49.7746 60.076 49.9249 59.9245Z"
        fill={theme.texto}
      />
      <path
        d="M49.7744 40.2275C49.7744 40.3791 49.7744 40.6821 49.624 40.8336C49.1729 42.8033 48.5714 44.6215 47.5188 46.2881C45.8647 48.5608 43.609 49.9245 40.7519 50.2275C40.4511 50.2275 40.3007 50.2275 40 50.2275C41.6541 50.2275 43.3082 50.6821 44.6616 51.5911C46.1654 52.5002 47.218 53.8639 48.1203 55.379C48.8722 56.8942 49.4737 58.4093 49.624 60.076C49.624 60.076 49.624 60.076 49.624 60.2275C44.2105 60.2275 39.6992 55.682 39.6992 50.2275C39.8496 44.6215 44.3609 40.2275 49.7744 40.2275Z"
        fill={theme.texto}
      />
      <path
        d="M29.9248 19.9245C30.2256 17.8032 30.9774 15.682 32.3308 14.0154C33.8346 11.8942 35.9399 10.6821 38.6466 10.379C38.9474 10.379 39.3985 10.379 39.6992 10.379C37.594 10.379 35.7895 9.77297 34.1353 8.40933C33.0827 7.50025 32.1805 6.43964 31.579 5.07601C30.8271 3.56086 30.3759 2.04572 30.0752 0.379054C30.0752 0.379054 30.0752 0.379054 30.0752 0.227539C35.4887 0.227539 40 4.77298 40 10.2275C40 15.682 35.6391 20.2275 30.0752 20.2275C29.9248 20.076 29.9248 20.076 29.9248 19.9245Z"
        fill={theme.texto}
      />
      <path
        d="M29.9248 0.227539C29.9248 0.379054 29.9248 0.682083 29.7744 0.833598C29.3233 2.80329 28.7218 4.62147 27.6692 6.28813C26.015 8.56085 23.7594 9.92448 20.9022 10.2275C20.6015 10.2275 20.4511 10.2275 20.1504 10.2275C21.8045 10.2275 23.4586 10.6821 24.812 11.5911C26.3158 12.5002 27.3684 13.8639 28.2707 15.379C29.0226 16.8942 29.6241 18.4093 29.7744 20.076C29.7744 20.076 29.7744 20.076 29.7744 20.2275C24.3609 20.2275 19.8496 15.682 19.8496 10.2275C20 4.62147 24.5113 0.227539 29.9248 0.227539Z"
        fill={theme.texto}
      />
      <path
        d="M29.9248 39.9245C30.2256 37.8032 30.9774 35.682 32.3308 34.0154C33.8346 31.8942 35.9399 30.6821 38.6466 30.379C38.9474 30.379 39.3985 30.379 39.6992 30.379C37.594 30.379 35.7895 29.773 34.1353 28.4093C33.0827 27.5002 32.1805 26.4396 31.579 25.076C30.8271 23.5609 30.3759 22.0457 30.0752 20.3791C30.0752 20.3791 30.0752 20.3791 30.0752 20.2275C35.4887 20.2275 40 24.773 40 30.2275C40 35.682 35.6391 40.2275 30.0752 40.2275C29.9248 40.076 29.9248 40.076 29.9248 39.9245Z"
        fill={theme.texto}
      />
      <path
        d="M29.9248 20.2275C29.9248 20.3791 29.9248 20.6821 29.7744 20.8336C29.3233 22.8033 28.7218 24.6215 27.6692 26.2881C26.015 28.5608 23.7594 29.9245 20.9022 30.2275C20.6015 30.2275 20.4511 30.2275 20.1504 30.2275C21.8045 30.2275 23.4586 30.6821 24.812 31.5911C26.3158 32.5002 27.3684 33.8639 28.2707 35.379C29.0226 36.8942 29.6241 38.4093 29.7744 40.076C29.7744 40.076 29.7744 40.076 29.7744 40.2275C24.3609 40.2275 19.8496 35.682 19.8496 30.2275C20 24.6215 24.5113 20.2275 29.9248 20.2275Z"
        fill={theme.texto}
      />
      <path
        d="M29.9248 59.9245C30.2256 57.8032 30.9774 55.682 32.3308 54.0154C33.8346 51.8942 35.9399 50.6821 38.6466 50.379C38.9474 50.379 39.3985 50.379 39.6992 50.379C37.594 50.379 35.7895 49.773 34.1353 48.4093C33.0827 47.5002 32.1805 46.4396 31.579 45.076C30.8271 43.5609 30.3759 42.0457 30.0752 40.3791C30.0752 40.3791 30.0752 40.3791 30.0752 40.2275C35.4887 40.2275 40 44.773 40 50.2275C40 55.682 35.6391 60.2275 30.0752 60.2275C29.9248 60.076 29.9248 60.076 29.9248 59.9245Z"
        fill={theme.texto}
      />
      <path
        d="M29.9248 40.2275C29.9248 40.3791 29.9248 40.6821 29.7744 40.8336C29.3233 42.8033 28.7218 44.6215 27.6692 46.2881C26.015 48.5608 23.7594 49.9245 20.9022 50.2275C20.6015 50.2275 20.4511 50.2275 20.1504 50.2275C21.8045 50.2275 23.4586 50.6821 24.812 51.5911C26.3158 52.5002 27.3684 53.8639 28.2707 55.379C29.0226 56.8942 29.6241 58.4093 29.7744 60.076C29.7744 60.076 29.7744 60.076 29.7744 60.2275C24.3609 60.2275 19.8496 55.682 19.8496 50.2275C20 44.6215 24.5113 40.2275 29.9248 40.2275Z"
        fill={theme.texto}
      />
      <path
        d="M10.0752 19.9245C10.3759 17.8032 11.1278 15.682 12.4812 14.0154C13.985 11.8942 16.0902 10.6821 18.797 10.379C19.0978 10.379 19.5489 10.379 19.8496 10.379C17.7444 10.379 15.9399 9.77297 14.2857 8.40933C13.2331 7.50025 12.3308 6.43964 11.7293 5.07601C10.9775 3.56086 10.5263 2.04572 10.2256 0.379054C10.2256 0.379054 10.2256 0.379054 10.2256 0.227539C15.6391 0.227539 20.1504 4.77298 20.1504 10.2275C20.1504 15.682 15.7895 20.2275 10.2256 20.2275C10.0752 20.076 10.0752 20.076 10.0752 19.9245Z"
        fill={theme.texto}
      />
      <path
        d="M10.0752 0.227539C10.0752 0.379054 10.0752 0.682083 9.92483 0.833598C9.4737 2.80329 8.87218 4.62147 7.81955 6.28813C6.16541 8.56085 3.90977 9.92448 1.05263 10.2275C0.751876 10.2275 0.6015 10.2275 0.300748 10.2275C1.95488 10.2275 3.60903 10.6821 4.96241 11.5911C6.46618 12.5002 7.51881 13.8639 8.42107 15.379C9.17295 16.8942 9.77445 18.4093 9.92483 20.076C9.92483 20.076 9.92483 20.076 9.92483 20.2275C4.51129 20.2275 0 15.682 0 10.2275C0.150376 4.62147 4.51128 0.227539 10.0752 0.227539Z"
        fill={theme.texto}
      />
      <path
        d="M10.0752 39.9245C10.3759 37.8032 11.1278 35.682 12.4812 34.0154C13.985 31.8942 16.0902 30.6821 18.797 30.379C19.0978 30.379 19.5489 30.379 19.8496 30.379C17.7444 30.379 15.9399 29.773 14.2857 28.4093C13.2331 27.5002 12.3308 26.4396 11.7293 25.076C10.9775 23.5609 10.5263 22.0457 10.2256 20.3791C10.2256 20.3791 10.2256 20.3791 10.2256 20.2275C15.6391 20.2275 20.1504 24.773 20.1504 30.2275C20.1504 35.682 15.7895 40.2275 10.2256 40.2275C10.0752 40.076 10.0752 40.076 10.0752 39.9245Z"
        fill={theme.texto}
      />
      <path
        d="M10.0752 20.2275C10.0752 20.3791 10.0752 20.6821 9.92483 20.8336C9.4737 22.8033 8.87218 24.6215 7.81955 26.2881C6.16541 28.5608 3.90977 29.9245 1.05263 30.2275C0.751876 30.2275 0.6015 30.2275 0.300748 30.2275C1.95488 30.2275 3.60903 30.6821 4.96241 31.5911C6.46618 32.5002 7.51881 33.8639 8.42107 35.379C9.17295 36.8942 9.77445 38.4093 9.92483 40.076C9.92483 40.076 9.92483 40.076 9.92483 40.2275C4.51129 40.2275 0 35.682 0 30.2275C0.150376 24.6215 4.51128 20.2275 10.0752 20.2275Z"
        fill={theme.texto}
      />
      <path
        d="M10.0752 59.9245C10.3759 57.8032 11.1278 55.682 12.4812 54.0154C13.985 51.8942 16.0902 50.6821 18.797 50.379C19.0978 50.379 19.5489 50.379 19.8496 50.379C17.7444 50.379 15.9399 49.773 14.2857 48.4093C13.2331 47.5002 12.3308 46.4396 11.7293 45.076C10.9775 43.5609 10.5263 42.0457 10.2256 40.3791C10.2256 40.3791 10.2256 40.3791 10.2256 40.2275C15.6391 40.2275 20.1504 44.773 20.1504 50.2275C20.1504 55.682 15.7895 60.2275 10.2256 60.2275C10.0752 60.076 10.0752 60.076 10.0752 59.9245Z"
        fill={theme.texto}
      />
      <path
        d="M10.0752 40.2275C10.0752 40.3791 10.0752 40.6821 9.92483 40.8336C9.4737 42.8033 8.87218 44.6215 7.81955 46.2881C6.16541 48.5608 3.90977 49.9245 1.05263 50.2275C0.751876 50.2275 0.6015 50.2275 0.300748 50.2275C1.95488 50.2275 3.60903 50.6821 4.96241 51.5911C6.46618 52.5002 7.51881 53.8639 8.42107 55.379C9.17295 56.8942 9.77445 58.4093 9.92483 60.076C9.92483 60.076 9.92483 60.076 9.92483 60.2275C4.51129 60.2275 0 55.682 0 50.2275C0.150376 44.6215 4.51128 40.2275 10.0752 40.2275Z"
        fill={theme.texto}
      />
    </SVG>
  );
}
const SVG = styled.svg`
  width: 60px;
  height: 60px;

  & path {
    transition: 0.2s;
  }

  ${breakpoints.min_desktop} {
    width: 123.902px;
    height: 123px;
  }
`;

export default Teamwork;
