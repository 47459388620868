import React from "react";
import styled, { useTheme } from "styled-components";
import { breakpoints } from "../../components/base/breakpoints";

function Sciences() {
  const theme = useTheme();

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="50"
      viewBox="0 0 44 50"
      fill="none"
    >
      <path
        d="M13.6715 22.9673H18.8985V28.1943C18.8985 30.2534 20.5352 31.8902 22.5944 31.8902H29.2998C31.3589 31.8902 32.9956 30.2534 32.9956 28.1943V22.9673H38.2227C40.2818 22.9673 41.9186 21.3305 41.9186 19.2714V12.6188C41.9186 10.5597 40.2818 8.92291 38.2227 8.92291H32.9956V3.69588C32.9956 1.63675 31.3589 0 29.2998 0H22.5944C20.5352 0 18.8985 1.63675 18.8985 3.69588V8.92291H13.6715C11.6123 8.92291 9.97559 10.5597 9.97559 12.6188V19.3242C9.97559 21.3305 11.6651 22.9673 13.6715 22.9673ZM13.1435 12.6188C13.1435 12.3548 13.3547 12.0908 13.6715 12.0908H20.4825C21.38 12.0908 22.0664 11.4044 22.0664 10.5069V3.69588C22.0664 3.43189 22.2776 3.1679 22.5944 3.1679H29.2998C29.5638 3.1679 29.8278 3.37909 29.8278 3.69588V10.5069C29.8278 11.4044 30.5141 12.0908 31.4117 12.0908H38.2227C38.4867 12.0908 38.7507 12.302 38.7507 12.6188V19.3242C38.7507 19.5882 38.5395 19.8522 38.2227 19.8522H31.3589C30.4613 19.8522 29.775 20.5385 29.775 21.4361V28.2471C29.775 28.5111 29.5638 28.7751 29.247 28.7751H22.5944C22.3304 28.7751 22.0664 28.5639 22.0664 28.2471V21.3833C22.0664 20.4857 21.38 19.7994 20.4825 19.7994H13.6715C13.4075 19.7994 13.1435 19.5882 13.1435 19.2714V12.6188Z"
        fill={theme.texto}
      />
      <path
        d="M43.2916 38.015C42.7108 36.1671 41.0213 35.0055 39.1205 35.0583C39.0677 35.0583 38.9621 35.0583 38.9093 35.0583L27.6633 36.8006C27.0825 35.3751 25.657 34.0551 23.3338 33.4743L14.5693 30.9928C13.1966 30.6232 11.7182 30.5704 10.3454 30.8344L2.2673 32.524C1.42253 32.6824 0.841749 33.5271 1.05294 34.3719C1.21134 35.2167 2.05611 35.7975 2.90088 35.5863L10.9262 33.9495C11.8238 33.7383 12.7742 33.7911 13.6717 34.0551L22.4891 36.5366H22.5419C24.2314 36.959 24.865 37.9094 24.8122 38.2262C24.8122 38.3318 24.4426 38.6486 23.4394 38.6486L13.3549 37.0118C12.5102 36.8534 11.6654 37.4342 11.5598 38.3318C11.4014 39.1766 11.9822 40.0213 12.8798 40.1269L23.1226 41.8165C23.2282 41.8165 23.281 41.8165 23.3866 41.8165C25.5514 41.8165 26.8185 41.0245 27.5049 40.0213L39.3845 38.1734C40.0709 38.1734 40.3349 38.7542 40.3877 38.9126C40.4405 39.071 40.5989 39.6517 40.0709 40.0741C38.8037 40.6549 32.2039 43.4532 28.1385 45.4068C26.1849 46.3571 24.0202 46.8323 21.8555 46.8323H21.5915C20.1659 46.8323 18.7932 46.6211 17.4204 46.1987L10.1343 44.034C9.18388 43.77 8.23351 43.5588 7.23034 43.506L2.68969 43.1364C1.84492 43.0836 1.05294 43.7172 1.00014 44.562C0.947346 45.4596 1.58093 46.1987 2.4257 46.2515L6.96635 46.6211C7.75833 46.6739 8.4975 46.8323 9.23668 47.0435L16.5228 49.2082C18.1596 49.6834 19.9019 49.9474 21.5915 49.9474H21.8555C24.4954 49.9474 27.1353 49.3666 29.5112 48.2051C33.8935 46.0931 41.2325 42.978 41.3381 42.978C41.4437 42.9252 41.4965 42.8724 41.602 42.8196C43.186 41.8165 43.8724 39.8629 43.2916 38.015Z"
        fill={theme.texto}
      />
    </SVG>
  );
}

const SVG = styled.svg`
  width: 21.249px;
  height: 24.974px;

  & path {
    transition: 0.2s;
  }

  ${breakpoints.min_desktop} {
    width: 42.499px;
    height: 49.947px;
  }
`;

export default Sciences;
