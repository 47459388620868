import React from "react";
import styled, { useTheme } from "styled-components";
import { breakpoints } from "../../components/base/breakpoints";

function Industry() {
  const theme = useTheme();

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="51"
      viewBox="0 0 40 51"
      fill="none"
    >
      <path
        d="M37.5607 50.9999H1.84645C0.826045 50.9999 0 50.1738 0 49.1534V33.3614C0 32.341 0.826045 31.5149 1.84645 31.5149H1.94363V22.8172C1.94363 21.7967 2.76968 20.9707 3.79009 20.9707H7.14286C8.16327 20.9707 8.98931 21.7967 8.98931 22.8172V31.5149H9.86395V22.8172C9.86395 21.7967 10.69 20.9707 11.7104 20.9707H14.8688C15.8892 20.9707 16.7153 21.7967 16.7153 22.8172V30.3973L25.7532 24.9552C26.3363 24.615 27.0651 24.615 27.6482 24.9066C28.2313 25.2467 28.5714 25.8784 28.5714 26.5101V30.203L36.4917 24.9552C37.0748 24.5664 37.8037 24.5664 38.3868 24.858C38.9699 25.1981 39.3586 25.8298 39.3586 26.5101V49.0562C39.4072 50.1738 38.5812 50.9999 37.5607 50.9999ZM2.91545 48.0844H36.4917V28.5509L28.5714 33.7987C27.9883 34.1874 27.2595 34.1874 26.6764 33.8959C26.0933 33.5557 25.7046 32.9241 25.7046 32.2438V28.4537L16.6667 33.8473C16.0836 34.1874 15.3547 34.1874 14.7716 33.8959C14.1885 33.5557 13.8484 32.9241 13.8484 32.2924V23.8862H12.828V32.5839C12.828 33.6043 12.0019 34.4304 10.9815 34.4304H7.9689C6.94849 34.4304 6.12245 33.6043 6.12245 32.5839V23.8862H4.85909V32.5839C4.85909 33.6043 4.03304 34.4304 3.01263 34.4304H2.91545V48.0844ZM23.4694 46.1894H18.2702C17.2498 46.1894 16.4237 45.3633 16.4237 44.3429V39.1437C16.4237 38.1233 17.2498 37.2972 18.2702 37.2972H23.4694C24.4898 37.2972 25.3158 38.1233 25.3158 39.1437V44.3429C25.3158 45.3633 24.4898 46.1894 23.4694 46.1894ZM19.3392 43.2739H22.449V40.1641H19.3392V43.2739ZM11.7104 46.1894H6.51118C5.49077 46.1894 4.66472 45.3633 4.66472 44.3429V39.1437C4.66472 38.1233 5.49077 37.2972 6.51118 37.2972H11.7104C12.7308 37.2972 13.5569 38.1233 13.5569 39.1437V44.3429C13.5569 45.3633 12.7308 46.1894 11.7104 46.1894ZM7.58018 43.2739H10.69V40.1641H7.58018V43.2739Z"
        fill={theme.texto}
      />
      <path
        d="M27.1621 20.4362C25.3643 20.4362 24.1981 19.8532 23.2749 19.3672C22.1573 18.7842 21.3798 18.3954 18.8531 19.3187L18.513 19.4644C17.5897 19.8046 16.5693 20.1933 15.3546 20.1933C14.5285 20.1933 13.6539 20.0475 12.8278 19.8046C10.4955 19.1243 8.98916 19.2701 7.53143 19.4158C7.23988 19.4644 6.99693 19.4644 6.70538 19.513H6.55961C2.96389 19.4158 1.02025 16.6462 0.971662 13.8765C0.923071 11.1554 2.72093 8.28853 6.31666 8.14275C6.94834 8.14275 8.26029 8.09416 9.2807 7.07375C10.9814 5.03294 13.994 4.01253 17.0066 4.49844C19.4362 4.88716 20.2622 4.20689 21.477 3.23507C22.7404 2.21467 24.2953 0.951304 27.3565 0.999895C33.6247 1.09708 36.9289 5.95616 36.9289 10.7181C36.9289 15.48 33.6247 20.3391 27.3565 20.4362C27.2593 20.4362 27.2107 20.4362 27.1621 20.4362ZM21.3312 15.3342C22.789 15.3342 23.8094 15.7715 24.8784 16.3546C25.6558 16.7433 26.2389 17.0835 27.2593 17.0349C31.5839 16.9863 33.479 13.8279 33.479 10.7181C33.479 7.60825 31.5353 4.49844 27.2593 4.40126C25.4615 4.35266 24.684 4.98435 23.615 5.85898C22.2059 7.02516 20.408 8.48289 16.4721 7.85121C14.6257 7.55966 12.7792 8.14275 11.856 9.30893L11.7588 9.45471C9.76661 11.4955 7.33707 11.5441 6.41384 11.5927C4.42161 11.6413 4.37302 13.5849 4.37302 13.8279C4.37302 14.168 4.5188 15.9659 6.46243 16.0145C6.70538 16.0145 6.89975 15.9659 7.1427 15.9659C8.79479 15.8201 10.787 15.6257 13.7511 16.5004H13.7997C14.2856 16.6462 14.7715 16.7433 15.2574 16.7433C15.8891 16.7433 16.5693 16.5004 17.2496 16.2088L17.6383 16.0631C19.1932 15.5286 20.3594 15.3342 21.3312 15.3342Z"
        fill={theme.texto}
      />
    </SVG>
  );
}
const SVG = styled.svg`
  width: 19.68px;
  height: 25.001px;

  & path {
    transition: 0.2s;
  }

  ${breakpoints.min_desktop} {
    width: 39.361px;
    height: 50.002px;
  }
`;

export default Industry;
