import React from "react";
import styled, { useTheme } from "styled-components";
import { breakpoints } from "../../components/base/breakpoints";

function Innovation() {
  const theme = useTheme();

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
    >
      <path
        d="M59.8488 60.2277C26.7506 60.2277 0 33.4771 0 0.378906V60.2277H59.8488Z"
        fill={theme.texto}
      />
      <path
        d="M8.61456 21.991C11.4861 28.6408 15.4156 34.535 20.5541 39.6736C25.6926 44.8121 31.5868 48.7416 38.2367 51.6131C45.0377 54.4846 52.4432 55.996 59.8488 55.996V50.8574C53.0478 50.8574 46.3979 49.4972 40.2014 46.928C34.1561 44.3587 28.8664 40.7315 24.1813 36.0464C19.4962 31.3612 15.869 26.0716 13.2997 20.0262C10.7304 13.8298 9.37023 7.17991 9.37023 0.378906H4.23169C4.23169 7.93557 5.74303 15.19 8.61456 21.991Z"
        fill={theme.texto}
      />
      <path
        d="M17.6826 18.2124C19.9496 23.6532 23.2746 28.6406 27.5063 32.7212C31.738 36.9529 36.5743 40.2779 42.0151 42.5449C47.607 44.963 53.6524 46.1721 59.8488 46.1721V41.0335C54.408 41.0335 48.9672 39.9756 43.9798 37.8597C39.1436 35.7439 34.7607 32.8723 30.9824 29.094C27.204 25.3157 24.3325 20.9328 22.2166 16.0965C20.1008 11.1091 19.0428 5.81947 19.0428 0.227539H13.9043C14.0554 6.57514 15.2645 12.6205 17.6826 18.2124Z"
        fill={theme.texto}
      />
      <path
        d="M27.2041 14.1318C29.0177 18.3635 31.5869 22.1419 34.7607 25.4668C38.0857 28.7917 41.864 31.361 46.0957 33.0235C50.4786 34.8371 55.1637 35.7439 59.8489 35.7439V30.6053C55.7683 30.6053 51.8388 29.8497 48.0605 28.1872C44.4333 26.6759 41.2595 24.4089 38.3879 21.6885C35.6675 18.9681 33.4005 15.6431 31.8892 12.0159C30.3779 8.23761 29.4711 4.30814 29.4711 0.227539H24.3325C24.3325 5.21494 25.2393 9.74894 27.2041 14.1318Z"
        fill={theme.texto}
      />
      <path
        d="M36.8766 10.0517C38.0856 13.0743 39.8992 15.6436 42.3174 18.0617C44.5844 20.3287 47.3048 22.1423 50.3274 23.5025C53.3501 24.8627 56.675 25.4673 60 25.4673V20.3287C57.2796 20.3287 54.7103 19.8753 52.2922 18.8174C49.874 17.7595 47.7582 16.3993 45.9446 14.5857C44.131 12.7721 42.6196 10.6562 41.7128 8.23807C40.6549 5.81994 40.2015 3.25067 40.2015 0.530273H35.063C34.9118 3.70407 35.5164 7.02901 36.8766 10.0517Z"
        fill={theme.texto}
      />
      <path
        d="M45.9447 6.27311C46.7003 8.08671 47.7583 9.74917 49.1185 11.1094C50.4787 12.4696 52.1411 13.5275 53.9547 14.2832C55.7683 15.0388 57.7331 15.4922 59.8489 15.4922V10.3537C58.4887 10.3537 57.1285 10.0514 55.9195 9.59804C54.7104 9.14464 53.6525 8.38897 52.7457 7.48217C51.8389 6.57537 51.0832 5.51744 50.6298 4.30837C50.1764 3.09931 49.8741 1.73911 49.8741 0.378906L44.7356 0.378906C44.7356 2.34364 45.189 4.45951 45.9447 6.27311Z"
        fill={theme.texto}
      />
      <path
        d="M59.849 0.378906H54.7104C54.7104 1.13457 54.8616 1.73911 55.1638 2.34364C55.4661 2.94817 55.7684 3.55271 56.2218 4.00611C56.6752 4.45951 57.2797 4.91291 57.8842 5.06404C58.4888 5.36631 59.2444 5.51744 59.849 5.51744V0.378906Z"
        fill={theme.texto}
      />
    </SVG>
  );
}

const SVG = styled.svg`
  width: 60px;
  height: 60px;

  & path {
    transition: 0.2s;
  }

  ${breakpoints.min_desktop} {
    width: 114.586px;
    height: 114.614px;
  }
`;

export default Innovation;
