import React from "react";
import styled, { useTheme } from "styled-components";
import { breakpoints, colors } from "../../components/base/breakpoints";

function Tres() {
  const theme = useTheme();

  return (
    <SVG
      width="157"
      height="157"
      viewBox="0 0 157 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="76"
        cy="76"
        r="76"
        fill={theme.nombre === "light" ? colors.amarillo : colors.rosaush}
      />
      <circle
        cx="81"
        cy="80"
        r="75"
        stroke={theme.texto}
        strokeWidth="2"
        strokeDasharray="6 6"
      />
      <path
        d="M52.2824 65.7C52.5491 58.1667 54.8824 52.4 59.2824 48.4C63.6824 44.3333 69.8158 42.3 77.6824 42.3C82.8158 42.3 87.1824 43.2 90.7824 45C94.4491 46.7333 97.2158 49.1333 99.0824 52.2C100.949 55.2 101.882 58.6 101.882 62.4C101.882 66.9333 100.782 70.5667 98.5824 73.3C96.3824 75.9667 93.8824 77.7667 91.0824 78.7V79.1C99.1491 82.1 103.182 87.8333 103.182 96.3C103.182 100.5 102.216 104.2 100.282 107.4C98.3491 110.6 95.5491 113.1 91.8824 114.9C88.2158 116.7 83.8491 117.6 78.7824 117.6C70.4491 117.6 63.8491 115.6 58.9824 111.6C54.1824 107.533 51.6824 101.333 51.4824 93H70.5824C70.4491 95.6667 71.0158 97.7333 72.2824 99.2C73.5491 100.667 75.4491 101.4 77.9824 101.4C79.9158 101.4 81.4158 100.8 82.4824 99.6C83.6158 98.4 84.1824 96.8 84.1824 94.8C84.1824 92.2667 83.3491 90.4 81.6824 89.2C80.0824 88 77.4491 87.4 73.7824 87.4H70.2824V71.5H73.6824C76.2158 71.5667 78.3491 71.1667 80.0824 70.3C81.8824 69.3667 82.7824 67.5333 82.7824 64.8C82.7824 62.7333 82.2824 61.2 81.2824 60.2C80.2824 59.1333 78.9158 58.6 77.1824 58.6C75.2491 58.6 73.8158 59.3 72.8824 60.7C72.0158 62.0333 71.5158 63.7 71.3824 65.7H52.2824Z"
        fill={colors.textonegro}
      />
    </SVG>
  );
}

const SVG = styled.svg`
  width: 48px;
  height: 48px;

  & circle {
    transition: 0.2s;
  }

  ${breakpoints.min_desktop} {
    width: 152px;
    height: 152px;
  }
`;

export default Tres;
