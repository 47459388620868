import React from "react";
import styled, { useTheme } from "styled-components";
import { breakpoints } from "../../components/base/breakpoints";

function FinalLinea() {
  const theme = useTheme();
  return (
    <SVG
      width="29"
      height="86"
      viewBox="0 0 29 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="14.2666"
        y1="0.118164"
        x2="14.2666"
        y2="68.1182"
        stroke={theme.texto}
        strokeDasharray="3 3"
      />
      <g clipPath="url(#clip0_198_5144)">
        <path
          d="M13.0581 72.3201C13.1069 71.2884 13.1588 70.2904 13.2046 69.2923C13.2443 68.5079 13.2748 67.7265 13.3236 66.9452C13.3328 66.7773 13.287 66.6644 13.1619 66.5575C12.3225 65.8159 11.4923 65.0589 10.653 64.3172C10.0822 63.8136 9.96927 63.6763 10.2348 62.6843C10.5797 62.7728 10.9307 62.843 11.2695 62.956C11.9379 63.1727 12.6033 63.4046 13.2687 63.6427C14.0775 63.9266 14.8894 63.9266 15.7074 63.6824C16.4094 63.4748 17.1114 63.2612 17.8164 63.0628C18.1552 62.9682 18.4971 62.9071 18.845 62.8339C18.8664 62.8919 18.8755 62.9193 18.8817 62.9437C19.0923 63.8747 19.0678 63.8808 18.3384 64.5004C17.4929 65.2176 16.6749 65.9624 15.8508 66.701C15.7989 66.7468 15.7654 66.8353 15.7654 66.9024C15.8295 68.7185 15.9027 70.5376 15.9699 72.3567C15.9699 72.3811 15.9821 72.4116 15.9943 72.4544C18.9885 71.1206 21.9644 69.7929 24.9982 68.4438C24.9647 68.5994 24.9555 68.7124 24.9158 68.8131C24.6472 69.5029 24.3756 70.1896 24.0948 70.8733C24.0582 70.9588 23.9849 71.0442 23.9117 71.0992C21.4394 72.8939 18.9641 74.6855 16.4887 76.4771C16.3575 76.5718 16.3117 76.6725 16.3087 76.8342C16.2995 78.4305 16.3026 80.0268 16.2568 81.6231C16.2385 82.2519 16.1683 82.8898 16.0218 83.5002C15.7532 84.6295 14.5842 85.1759 13.577 84.6997C13.1252 84.4861 12.8597 84.1168 12.7162 83.6498C12.4873 82.9142 12.4385 82.1512 12.4507 81.3881C12.4751 80.0635 12.5239 78.7449 12.585 77.4203C12.6064 76.9838 12.4965 76.6359 12.1546 76.3612C11.2481 75.6317 10.3661 74.8656 9.43819 74.1636C8.01587 73.0892 6.56303 72.0576 5.11629 71.0137C4.95758 70.8977 4.88433 70.7696 4.85381 70.5803C4.73477 69.8508 4.59742 69.1214 4.45397 68.337C7.34134 69.6738 10.1829 70.9893 13.0581 72.3201Z"
          fill={theme.texto}
        />
      </g>
      <defs>
        <clipPath id="clip0_198_5144">
          <rect
            width="19.6657"
            height="19.1779"
            fill="white"
            transform="translate(28.2334 71.679) rotate(135)"
          />
        </clipPath>
      </defs>
    </SVG>
  );
}

const SVG = styled.svg`
  width: 27.467px;
  height: 85.467px;
  margin: 0 auto;
  display: block;
  padding: 16px 0 0;

  ${breakpoints.min_desktop} {
    display: none;
  }
`;

export default FinalLinea;
