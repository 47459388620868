import React from "react";
import styled, { useTheme } from "styled-components";
import { breakpoints, colors } from "../../components/base/breakpoints";

function CirculoMobile() {
  const theme = useTheme();

  return (
    <SVG
      width="274"
      height="131"
      viewBox="0 0 274 131"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M142.401 102.02C140.659 101.759 138.829 101.628 137 101.628C135.17 101.628 133.384 101.759 131.598 102.02C114.609 104.634 101.584 119.314 101.584 137C101.584 154.686 114.609 169.41 131.598 171.98C133.34 172.241 135.17 172.372 137 172.372C138.829 172.372 140.615 172.241 142.401 171.98C159.39 169.366 172.415 154.686 172.415 137C172.415 119.314 159.347 104.59 142.401 102.02ZM137 152.072C135.954 152.072 134.952 151.985 133.95 151.767C127.068 150.373 121.927 144.275 121.927 137C121.927 129.725 127.111 123.627 133.95 122.233C134.952 122.015 135.954 121.928 137 121.928C138.045 121.928 139.047 122.015 140.049 122.233C146.932 123.627 152.072 129.725 152.072 137C152.072 144.275 146.888 150.373 140.049 151.767C139.047 151.985 138.045 152.072 137 152.072Z"
        fill={theme.nombre === "light" ? colors.rojoush : colors.amarillo}
      />
      <path
        d="M137 60.8984C95.0503 60.8984 60.8983 95.0504 60.8983 137C60.8983 178.949 95.0503 213.101 137 213.101C178.949 213.101 213.101 178.949 213.101 137C213.101 95.0504 178.949 60.8984 137 60.8984ZM137 192.758C106.246 192.758 81.2414 167.754 81.2414 137C81.2414 106.246 106.246 81.2415 137 81.2415C167.754 81.2415 192.758 106.246 192.758 137C192.758 167.754 167.754 192.758 137 192.758Z"
        fill={theme.nombre === "light" ? colors.rojoush : colors.amarillo}
      />
      <path
        d="M137 0C61.4649 0 0 61.4649 0 137C0 212.535 61.4649 274 137 274C212.535 274 274 212.535 274 137C274 61.4649 212.535 0 137 0ZM137 233.314C83.8118 233.314 40.6862 190.188 40.6862 137C40.6862 83.8118 83.8118 40.6862 137 40.6862C190.188 40.6862 233.314 83.8118 233.314 137C233.314 190.188 190.188 233.314 137 233.314Z"
        fill={theme.nombre === "light" ? colors.rojoush : colors.amarillo}
      />
    </SVG>
  );
}

const SVG = styled.svg`
  display: block;
  margin-bottom: 10px;
  width: 273px;
  height: 131px;

  & path {
    transition: 0.2s;
  }

  ${breakpoints.min_desktop} {
    display: none;
  }
`;

export default CirculoMobile;
