import React from "react";
import Container from "../base/Container";
import { StyledContainer, SVG, Content } from "./ValoresComp";
import { useTheme } from "styled-components";

function Valores() {
  const theme = useTheme();

  return (
    <StyledContainer>
      <Container className="valores">
        <Content>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
          >
            <mask
              id="mask0_358_56"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="60"
              height="60"
            >
              <path
                d="M30.0424 59.5724C46.3709 59.5724 59.6078 46.3324 59.6078 30C59.6078 13.6676 46.3709 0.427551 30.0424 0.427551C13.7139 0.427551 0.477051 13.6676 0.477051 30C0.477051 46.3324 13.7139 59.5724 30.0424 59.5724Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_358_56)">
              <path
                d="M49.9018 19.8438C50.2004 17.7529 50.947 15.6619 52.2909 14.019C53.7841 11.928 55.8746 10.7331 58.5623 10.4344C58.861 10.4344 59.3089 10.4344 59.6076 10.4344C57.5171 10.4344 55.7252 9.837 54.0827 8.4928C52.7388 7.44731 51.9922 6.25246 51.2456 5.05761C50.499 3.56406 50.0511 2.0705 49.7524 0.427585C49.7524 0.427585 49.7524 0.427585 49.7524 0.278229C55.128 0.278229 59.6076 4.7589 59.6076 10.1357C59.6076 15.5125 55.2773 19.9932 49.7524 19.9932C49.7524 19.9932 49.7524 19.9932 49.9018 19.8438Z"
                fill={theme.texto}
              />
              <path
                d="M49.7525 0.427582C49.7525 0.576938 49.7525 0.875649 49.6032 1.025C49.1552 2.96663 48.5579 4.7589 47.5127 6.40181C45.8702 8.64215 43.6304 9.98635 40.7933 10.2851C40.4946 10.2851 40.3453 10.2851 40.0467 10.2851C41.6892 10.2851 43.3317 10.7331 44.6756 11.6293C46.1688 12.5254 47.214 13.8696 48.11 15.3632C48.8566 16.8567 49.4538 18.3503 49.6032 19.9932C49.6032 19.9932 49.6032 19.9932 49.6032 20.1425C44.2276 20.1425 39.748 15.6619 39.748 10.2851C39.8974 4.7589 44.377 0.427582 49.7525 0.427582Z"
                fill={theme.texto}
              />
              <path
                d="M49.9018 39.5587C50.2004 37.4678 50.947 35.3768 52.2909 33.7339C53.7841 31.6429 55.8746 30.448 58.5623 30.1493C58.861 30.1493 59.3089 30.1493 59.6076 30.1493C57.5171 30.1493 55.7252 29.5519 54.0827 28.2077C52.7388 27.1622 51.9922 25.9674 51.2456 24.7725C50.499 23.279 50.0511 21.7854 49.7524 20.1425C49.7524 20.1425 49.7524 20.1425 49.7524 19.9931C55.128 19.9931 59.6076 24.4738 59.6076 29.8506C59.6076 35.2274 55.2773 39.7081 49.7524 39.7081C49.7524 39.7081 49.7524 39.7081 49.9018 39.5587Z"
                fill={theme.texto}
              />
              <path
                d="M49.7525 20.1425C49.7525 20.2918 49.7525 20.5906 49.6032 20.7399C49.1552 22.6815 48.5579 24.4738 47.5127 26.1167C45.8702 28.3571 43.6304 29.7013 40.7933 30C40.4946 30 40.3453 30 40.0467 30C41.6892 30 43.3317 30.448 44.6756 31.3442C46.1688 32.2403 47.214 33.5845 48.11 35.0781C48.8566 36.5716 49.4538 38.0652 49.6032 39.7081C49.6032 39.7081 49.6032 39.7081 49.6032 39.8575C44.2276 39.8575 39.748 35.3768 39.748 30C39.8974 24.4738 44.377 20.1425 49.7525 20.1425Z"
                fill={theme.texto}
              />
              <path
                d="M49.9018 59.2737C50.2004 57.1828 50.947 55.0918 52.2909 53.4489C53.7841 51.3579 55.8746 50.163 58.5624 49.8643C58.861 49.8643 59.3089 49.8643 59.6076 49.8643C57.5171 49.8643 55.7253 49.2669 54.0828 47.9227C53.0375 47.0266 52.1416 45.9811 51.5443 44.6369C50.7977 43.1433 50.3498 41.6498 50.0511 40.0068C50.0511 40.0068 50.0511 40.0068 50.0511 39.8575C55.4266 39.8575 59.9062 44.3382 59.9062 49.715C59.9062 55.0918 55.576 59.5724 50.0511 59.5724C49.7525 59.4231 49.7525 59.4231 49.9018 59.2737Z"
                fill={theme.texto}
              />
              <path
                d="M49.7525 39.8575C49.7525 40.0068 49.7525 40.3055 49.6032 40.4549C49.1552 42.3965 48.5579 44.1888 47.5127 45.8317C45.8702 48.0721 43.6304 49.4163 40.7933 49.5656C40.4946 49.5656 40.3453 49.5656 40.0467 49.5656C41.6892 49.5656 43.3317 50.0137 44.6756 50.9098C46.1688 51.8059 47.214 53.1501 48.11 54.6437C48.8566 56.1373 49.4538 57.6308 49.6032 59.2737C49.6032 59.2737 49.6032 59.2737 49.6032 59.4231C44.2276 59.4231 39.748 54.9424 39.748 49.5656C39.8974 44.1888 44.377 39.8575 49.7525 39.8575Z"
                fill={theme.texto}
              />
              <path
                d="M30.0425 19.8438C30.3411 17.7528 31.0877 15.6618 32.4316 14.0189C33.9248 11.9279 36.0153 10.7331 38.703 10.4344C39.0017 10.4344 39.4496 10.4344 39.7483 10.4344C37.6578 10.4344 35.866 9.83694 34.2234 8.49273C33.1782 7.5966 32.2823 6.55111 31.685 5.20691C30.9384 3.71335 30.4904 2.21979 30.1918 0.576877C30.1918 0.576877 30.1918 0.576877 30.1918 0.427521C35.5673 0.427521 40.0469 4.90819 40.0469 10.285C40.0469 15.6618 35.7166 20.1425 30.1918 20.1425C30.0425 19.9931 30.0425 19.9931 30.0425 19.8438Z"
                fill={theme.texto}
              />
              <path
                d="M30.0425 0.427551C30.0425 0.576907 30.0425 0.875619 29.8932 1.02497C29.5946 2.9666 28.848 4.75887 27.8027 6.40178C26.1602 8.64212 23.9204 9.98632 21.0833 10.285C20.7847 10.285 20.6354 10.285 20.3367 10.285C21.9792 10.285 23.6218 10.7331 24.9656 11.6292C26.4588 12.5254 27.5041 13.8696 28.4 15.3631C29.1466 16.8567 29.7439 18.3502 29.8932 19.9932C29.8932 19.9932 29.8932 19.9932 29.8932 20.1425C24.5177 20.1425 20.0381 15.6618 20.0381 10.285C20.1874 4.75887 24.667 0.427551 30.0425 0.427551Z"
                fill={theme.texto}
              />
              <path
                d="M30.0425 39.5588C30.3411 37.4678 31.0877 35.3768 32.4316 33.7339C33.9248 31.6429 36.0153 30.4481 38.703 30.1494C39.0017 30.1494 39.4496 30.1494 39.7483 30.1494C37.6578 30.1494 35.866 29.552 34.2234 28.2078C33.1782 27.3116 32.2823 26.2661 31.685 24.9219C30.9384 23.4284 30.4904 21.9348 30.1918 20.2919C30.1918 20.2919 30.1918 20.2919 30.1918 20.1425C35.5673 20.1425 40.0469 24.6232 40.0469 30C40.0469 35.3768 35.7166 39.8575 30.1918 39.8575C30.0425 39.7082 30.0425 39.7082 30.0425 39.5588Z"
                fill={theme.texto}
              />
              <path
                d="M30.0425 20.1425C30.0425 20.2918 30.0425 20.5906 29.8932 20.7399C29.4452 22.6815 28.848 24.4738 27.8027 26.1167C26.1602 28.3571 23.9204 29.7013 21.0833 30C20.7847 30 20.6354 30 20.3367 30C21.9792 30 23.6218 30.448 24.9656 31.3442C26.4588 32.2403 27.5041 33.5845 28.4 35.0781C29.1466 36.5716 29.7439 38.0652 29.8932 39.7081C29.8932 39.7081 29.8932 39.7081 29.8932 39.8575C24.5177 39.8575 20.0381 35.3768 20.0381 30C20.1874 24.4738 24.667 20.1425 30.0425 20.1425Z"
                fill={theme.texto}
              />
              <path
                d="M30.0425 59.2737C30.3411 57.1828 31.0877 55.0918 32.4316 53.4489C33.9248 51.3579 36.0153 50.163 38.703 49.8643C39.0017 49.8643 39.4496 49.8643 39.7483 49.8643C37.6578 49.8643 35.866 49.2669 34.2234 47.9227C33.1782 47.0266 32.2823 45.9811 31.685 44.6369C30.9384 43.1433 30.4904 41.6498 30.1918 40.0068C30.1918 40.0068 30.1918 40.0068 30.1918 39.8575C35.5673 39.8575 40.0469 44.3382 40.0469 49.715C40.0469 55.0918 35.7166 59.5724 30.1918 59.5724C30.0425 59.4231 30.0425 59.4231 30.0425 59.2737Z"
                fill={theme.texto}
              />
              <path
                d="M30.0425 39.8575C30.0425 40.0068 30.0425 40.3055 29.8932 40.4549C29.4452 42.3965 28.848 44.1888 27.8027 45.8317C26.1602 48.0721 23.9204 49.4163 21.0833 49.715C20.7847 49.715 20.6354 49.715 20.3367 49.715C21.9792 49.715 23.6218 50.163 24.9656 51.0592C26.4588 51.9553 27.5041 53.2995 28.4 54.7931C29.1466 56.2866 29.7439 57.7802 29.8932 59.4231C29.8932 59.4231 29.8932 59.4231 29.8932 59.5724C24.5177 59.5724 20.0381 55.0918 20.0381 49.715C20.1874 44.1888 24.667 39.8575 30.0425 39.8575Z"
                fill={theme.texto}
              />
              <path
                d="M10.332 19.8438C10.6307 17.7529 11.3773 15.6619 12.7212 14.019C14.2144 11.928 16.3048 10.7331 18.9926 10.4344C19.2912 10.4344 19.7392 10.4344 20.0378 10.4344C17.9474 10.4344 16.1555 9.837 14.513 8.4928C13.3184 7.44731 12.5718 6.25246 11.8252 5.05761C11.0786 3.56406 10.6307 2.0705 10.332 0.427585C10.332 0.427585 10.332 0.427585 10.332 0.278229C15.7076 0.278229 20.1871 4.7589 20.1871 10.1357C20.1871 15.5125 15.8569 19.9932 10.332 19.9932C10.332 19.9932 10.332 19.9932 10.332 19.8438Z"
                fill={theme.texto}
              />
              <path
                d="M10.3322 0.427582C10.3322 0.576938 10.3322 0.875649 10.1828 1.025C9.88421 2.96663 9.13761 4.7589 8.09237 6.40181C6.44985 8.64215 4.21005 9.98635 1.37297 10.1357C1.07433 10.1357 0.925011 10.1357 0.626371 10.1357C2.26889 10.1357 3.91141 10.5838 5.25529 11.4799C6.89781 12.5254 7.94305 13.7202 8.83897 15.3632C9.58557 16.8567 10.1828 18.3503 10.3322 19.9932C10.3322 19.9932 10.3322 19.9932 10.3322 20.1425C4.95665 20.1425 0.477051 15.6619 0.477051 10.2851C0.477051 4.7589 4.80733 0.427582 10.3322 0.427582Z"
                fill={theme.texto}
              />
              <path
                d="M10.332 39.5588C10.6307 37.4678 11.3773 35.3768 12.7212 33.7339C14.2144 31.6429 16.3048 30.4481 18.9926 30.1494C19.2912 30.1494 19.7392 30.1494 20.0378 30.1494C17.9474 30.1494 16.1555 29.552 14.513 28.2078C13.4678 27.3116 12.5718 26.2661 11.9746 24.9219C11.228 23.4284 10.78 21.9348 10.4814 20.2919C10.4814 20.2919 10.4814 20.2919 10.4814 20.1425C15.8569 20.1425 20.3365 24.6232 20.3365 30C20.3365 35.3768 16.0062 39.8575 10.4814 39.8575C10.332 39.7082 10.332 39.7082 10.332 39.5588Z"
                fill={theme.texto}
              />
              <path
                d="M10.3321 20.1425C10.3321 20.2918 10.3321 20.5906 10.1828 20.7399C9.7348 22.6815 9.13752 24.4738 8.09228 26.1167C6.44976 28.3571 4.20996 29.7013 1.37288 30C1.07424 30 0.924917 30 0.626277 30C2.2688 30 3.91132 30.448 5.2552 31.3442C6.7484 32.2403 7.79364 33.5845 8.68956 35.0781C9.43616 36.5716 10.0334 38.0652 10.1828 39.7081C10.1828 39.7081 10.1828 39.7081 10.1828 39.8575C4.80724 39.8575 0.327637 35.3768 0.327637 30C0.476957 24.4738 4.80724 20.1425 10.3321 20.1425Z"
                fill={theme.texto}
              />
              <path
                d="M10.332 59.2737C10.6307 57.1828 11.3773 55.0918 12.7212 53.4489C14.2144 51.3579 16.3048 50.163 18.9926 49.8643C19.2912 49.8643 19.7392 49.8643 20.0378 49.8643C17.9474 49.8643 16.1555 49.2669 14.513 47.9227C13.4678 47.0266 12.5718 45.9811 11.9746 44.6369C11.228 43.1433 10.78 41.6498 10.4814 40.0068C10.4814 40.0068 10.4814 40.0068 10.4814 39.8575C15.8569 39.8575 20.3365 44.3382 20.3365 49.715C20.3365 55.0918 16.0062 59.5724 10.4814 59.5724C10.332 59.4231 10.332 59.4231 10.332 59.2737Z"
                fill={theme.texto}
              />
              <path
                d="M10.3321 39.8575C10.3321 40.0068 10.3321 40.3055 10.1828 40.4549C9.7348 42.3965 9.13752 44.1888 8.09228 45.8317C6.44976 48.0721 4.20996 49.4163 1.37288 49.5656C1.07424 49.5656 0.924917 49.5656 0.626277 49.5656C2.2688 49.5656 3.91132 50.0137 5.2552 50.9098C6.7484 51.8059 7.79364 53.1501 8.68956 54.6437C9.43616 56.1373 10.0334 57.6308 10.1828 59.2737C10.1828 59.2737 10.1828 59.2737 10.1828 59.4231C4.80724 59.4231 0.327637 54.9424 0.327637 49.5656C0.476957 44.1888 4.80724 39.8575 10.3321 39.8575Z"
                fill={theme.texto}
              />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
          >
            <mask
              id="mask0_358_88"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="60"
              height="60"
            >
              <path
                d="M30.1733 59.5724C46.5018 59.5724 59.7386 46.3324 59.7386 30C59.7386 13.6676 46.5018 0.427551 30.1733 0.427551C13.8448 0.427551 0.60791 13.6676 0.60791 30C0.60791 46.3324 13.8448 59.5724 30.1733 59.5724Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_358_88)">
              <g style={{ mixBlendMode: "multiply" }}>
                <path
                  d="M30.173 44.4875C38.1723 44.4875 44.657 38.0012 44.657 30C44.657 21.9987 38.1723 15.5125 30.173 15.5125C22.1737 15.5125 15.689 21.9987 15.689 30C15.689 38.0012 22.1737 44.4875 30.173 44.4875Z"
                  fill={theme.nombre === "dark" ? theme.fondo : theme.texto}
                />
              </g>
              <path
                opacity="0.7"
                d="M15.0919 59.5725C23.0913 59.5725 29.576 53.0862 29.576 45.085C29.576 37.0837 23.0913 30.5974 15.0919 30.5974C7.09264 30.5974 0.60791 37.0837 0.60791 45.085C0.60791 53.0862 7.09264 59.5725 15.0919 59.5725Z"
                fill={theme.texto}
              />
              <path
                opacity="0.7"
                d="M45.2545 59.5725C53.2539 59.5725 59.7386 53.0862 59.7386 45.085C59.7386 37.0837 53.2539 30.5974 45.2545 30.5974C37.2552 30.5974 30.7705 37.0837 30.7705 45.085C30.7705 53.0862 37.2552 59.5725 45.2545 59.5725Z"
                fill={theme.texto}
              />
              <path
                opacity="0.7"
                d="M15.0919 29.5519C23.0913 29.5519 29.576 23.0656 29.576 15.0644C29.576 7.06315 23.0913 0.576874 15.0919 0.576874C7.09264 0.576874 0.60791 7.06315 0.60791 15.0644C0.60791 23.0656 7.09264 29.5519 15.0919 29.5519Z"
                fill={theme.texto}
              />
              <path
                opacity="0.7"
                d="M45.2545 29.5519C53.2539 29.5519 59.7386 23.0656 59.7386 15.0644C59.7386 7.06318 53.2539 0.576904 45.2545 0.576904C37.2552 0.576904 30.7705 7.06318 30.7705 15.0644C30.7705 23.0656 37.2552 29.5519 45.2545 29.5519Z"
                fill={theme.texto}
              />
              <path
                opacity="0.7"
                d="M15.0919 59.5725C23.0913 59.5725 29.576 53.0862 29.576 45.085C29.576 37.0837 23.0913 30.5974 15.0919 30.5974C7.09264 30.5974 0.60791 37.0837 0.60791 45.085C0.60791 53.0862 7.09264 59.5725 15.0919 59.5725Z"
                fill={theme.texto}
              />
              <path
                opacity="0.7"
                d="M45.2545 59.5725C53.2539 59.5725 59.7386 53.0862 59.7386 45.085C59.7386 37.0837 53.2539 30.5974 45.2545 30.5974C37.2552 30.5974 30.7705 37.0837 30.7705 45.085C30.7705 53.0862 37.2552 59.5725 45.2545 59.5725Z"
                fill={theme.texto}
              />
              <path
                opacity="0.7"
                d="M15.0919 29.5519C23.0913 29.5519 29.576 23.0656 29.576 15.0644C29.576 7.06315 23.0913 0.576874 15.0919 0.576874C7.09264 0.576874 0.60791 7.06315 0.60791 15.0644C0.60791 23.0656 7.09264 29.5519 15.0919 29.5519Z"
                fill={theme.texto}
              />
              <path
                opacity="0.7"
                d="M45.2545 29.5519C53.2539 29.5519 59.7386 23.0656 59.7386 15.0644C59.7386 7.06318 53.2539 0.576904 45.2545 0.576904C37.2552 0.576904 30.7705 7.06318 30.7705 15.0644C30.7705 23.0656 37.2552 29.5519 45.2545 29.5519Z"
                fill={theme.texto}
              />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
          >
            <mask
              id="mask0_358_102"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="60"
              height="60"
            >
              <path
                d="M30.3041 59.5724C46.6326 59.5724 59.8695 46.3324 59.8695 30C59.8695 13.6676 46.6326 0.427551 30.3041 0.427551C13.9756 0.427551 0.73877 13.6676 0.73877 30C0.73877 46.3324 13.9756 59.5724 30.3041 59.5724Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_358_102)">
              <path
                d="M59.8695 59.5724C27.1684 59.5724 0.73877 33.1365 0.73877 0.427551V59.5724H59.8695Z"
                fill={theme.texto}
              />
              <path
                d="M9.24971 21.7854C12.0868 28.3571 15.9691 34.182 21.046 39.2601C26.1229 44.3382 31.9463 48.2214 38.5164 51.0592C45.2358 53.8969 52.5525 55.3905 59.8692 55.3905V50.3124C53.1498 50.3124 46.5797 48.9682 40.4576 46.4291C34.4848 43.8901 29.2586 40.3055 24.6297 35.6755C20.0008 31.0455 16.4171 25.818 13.8786 19.8438C11.3402 13.7202 9.99632 7.14856 9.99632 0.427551H4.91943C4.91943 7.89534 6.41264 15.0644 9.24971 21.7854Z"
                fill={theme.texto}
              />
              <path
                d="M18.2091 18.0515C20.4489 23.4283 23.7339 28.3571 27.9149 32.3897C32.0958 36.5717 36.8741 39.8575 42.2496 42.0978C47.7744 44.4875 53.7472 45.6824 59.8693 45.6824V40.6043C54.4938 40.6043 49.1183 39.5588 44.1908 37.4678C39.4125 35.3768 35.0822 32.5391 31.3492 28.8052C27.6162 25.0713 24.7792 20.7399 22.6887 15.9606C20.5982 11.0318 19.553 5.80436 19.553 0.278198H14.4761C14.6254 6.55114 15.82 12.5254 18.2091 18.0515Z"
                fill={theme.texto}
              />
              <path
                d="M27.6164 14.0189C29.4082 18.2009 31.9467 21.9348 35.0824 25.2206C38.3674 28.5064 42.1004 31.0455 46.2814 32.6884C50.6117 34.4807 55.2406 35.3768 59.8695 35.3768V30.2987C55.8379 30.2987 51.9555 29.5519 48.2225 27.909C44.6388 26.4155 41.5031 24.1751 38.6661 21.4867C35.9783 18.7983 33.7385 15.5125 32.2453 11.9279C30.7521 8.19405 29.8562 4.3108 29.8562 0.278198H24.7793C24.7793 5.20694 25.6752 9.68761 27.6164 14.0189Z"
                fill={theme.texto}
              />
              <path
                d="M37.1726 9.98632C38.3672 12.9734 40.159 15.5125 42.5482 17.9022C44.788 20.1425 47.4757 21.9348 50.4621 23.279C53.4485 24.6232 56.7336 25.2206 60.0186 25.2206V20.1425C57.3308 20.1425 54.7924 19.6944 52.4033 18.649C50.0142 17.6035 47.9237 16.2593 46.1318 14.467C44.34 12.6747 42.8468 10.5837 41.9509 8.19405C40.9056 5.80436 40.4577 3.26531 40.4577 0.576904H35.3808C35.2315 3.71338 35.8288 6.9992 37.1726 9.98632Z"
                fill={theme.texto}
              />
              <path
                d="M46.1321 6.25243C46.8787 8.0447 47.9239 9.68761 49.2678 11.0318C50.6117 12.376 52.2542 13.4215 54.046 14.1683C55.8379 14.9151 57.779 15.3631 59.8695 15.3631V10.285C58.5256 10.285 57.1817 9.98632 55.9872 9.53825C54.7926 9.09019 53.7474 8.34341 52.8515 7.44727C51.9555 6.55114 51.2089 5.50565 50.761 4.3108C50.313 3.11596 50.0144 1.77175 50.0144 0.427551L44.9375 0.427551C44.9375 2.36918 45.3855 4.46016 46.1321 6.25243Z"
                fill={theme.texto}
              />
              <path
                d="M59.8694 0.427551H54.7925C54.7925 1.17433 54.9418 1.77175 55.2404 2.36918C55.5391 2.9666 55.8377 3.56402 56.2857 4.01209C56.7336 4.46016 57.3309 4.90822 57.9282 5.05758C58.5255 5.35629 59.2721 5.50565 59.8694 5.50565V0.427551Z"
                fill={theme.texto}
              />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
          >
            <mask
              id="mask0_358_114"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="60"
              height="60"
            >
              <path
                d="M30.4345 59.5724C46.763 59.5724 59.9998 46.3324 59.9998 30C59.9998 13.6676 46.763 0.427551 30.4345 0.427551C14.106 0.427551 0.869141 13.6676 0.869141 30C0.869141 46.3324 14.106 59.5724 30.4345 59.5724Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_358_114)">
              <path
                d="M50.2939 19.8437C50.5925 17.7527 51.3391 15.6617 52.683 14.0188C54.1762 11.9279 56.2667 10.733 58.9544 10.4343C59.2531 10.4343 59.701 10.4343 59.9997 10.4343C57.9092 10.4343 56.1174 9.83687 54.4748 8.49267C53.4296 7.59654 52.5337 6.55105 51.9364 5.20685C51.1898 3.71329 50.7418 2.21973 50.4432 0.576816C50.4432 0.576816 50.4432 0.576816 50.4432 0.42746C55.8187 0.42746 60.2983 4.90813 60.2983 10.2849C60.2983 15.6618 55.968 20.1424 50.4432 20.1424C50.1446 19.9931 50.1446 19.9931 50.2939 19.8437Z"
                fill={theme.texto}
              />
              <path
                d="M50.1446 0.42749C50.1446 0.576846 50.1446 0.875558 49.9953 1.02491C49.5473 2.96654 48.95 4.75881 47.9048 6.40172C46.2623 8.64206 44.0225 9.98626 41.1854 10.285C40.8868 10.285 40.7374 10.285 40.4388 10.285C42.0813 10.285 43.7238 10.733 45.0677 11.6292C46.5609 12.5253 47.6061 13.8695 48.5021 15.3631C49.2487 16.8566 49.8459 18.3502 49.9953 19.9931C49.9953 19.9931 49.9953 19.9931 49.9953 20.1425C44.6197 20.1425 40.1401 15.6618 40.1401 10.285C40.2895 4.75881 44.7691 0.42749 50.1446 0.42749Z"
                fill={theme.texto}
              />
              <path
                d="M50.2939 39.5587C50.5925 37.4678 51.3391 35.3768 52.683 33.7339C54.1762 31.6429 56.2667 30.448 58.9544 30.1493C59.2531 30.1493 59.701 30.1493 59.9997 30.1493C57.9092 30.1493 56.1174 29.5519 54.4748 28.2077C53.4296 27.3116 52.5337 26.2661 51.9364 24.9219C51.1898 23.4283 50.7418 21.9348 50.4432 20.2918C50.4432 20.2918 50.4432 20.2918 50.4432 20.1425C55.8187 20.1425 60.2983 24.6232 60.2983 30C60.2983 35.3768 55.968 39.8575 50.4432 39.8575C50.1446 39.7081 50.1446 39.7081 50.2939 39.5587Z"
                fill={theme.texto}
              />
              <path
                d="M50.1446 20.1424C50.1446 20.2918 50.1446 20.5905 49.9953 20.7398C49.5473 22.6815 48.95 24.4737 47.9048 26.1167C46.2623 28.357 44.0225 29.7012 41.1854 29.9999C40.8868 29.9999 40.7374 29.9999 40.4388 29.9999C42.0813 29.9999 43.7238 30.448 45.0677 31.3441C46.5609 32.2402 47.6061 33.5844 48.5021 35.078C49.2487 36.5716 49.8459 38.0651 49.9953 39.708C49.9953 39.708 49.9953 39.708 49.9953 39.8574C44.6197 39.8574 40.1401 35.3767 40.1401 29.9999C40.2895 24.4737 44.7691 20.1424 50.1446 20.1424Z"
                fill={theme.texto}
              />
              <path
                d="M50.2939 59.2737C50.5925 57.1827 51.3391 55.0917 52.683 53.4488C54.1762 51.3578 56.2667 50.163 58.9544 49.8643C59.2531 49.8643 59.701 49.8643 59.9997 49.8643C57.9092 49.8643 56.1174 49.2668 54.4748 47.9226C53.4296 47.0265 52.5337 45.981 51.9364 44.6368C51.1898 43.1433 50.7418 41.6497 50.4432 40.0068C50.4432 40.0068 50.4432 40.0068 50.4432 39.8574C55.8187 39.8574 60.2983 44.3381 60.2983 49.7149C60.2983 55.0917 55.968 59.5724 50.4432 59.5724C50.1446 59.423 50.1446 59.423 50.2939 59.2737Z"
                fill={theme.texto}
              />
              <path
                d="M50.1446 39.8574C50.1446 40.0068 50.1446 40.3055 49.9953 40.4548C49.5473 42.3965 48.95 44.1887 47.9048 45.8317C46.2623 48.072 44.0225 49.4162 41.1854 49.7149C40.8868 49.7149 40.7374 49.7149 40.4388 49.7149C42.0813 49.7149 43.7238 50.163 45.0677 51.0591C46.5609 51.9552 47.6061 53.2994 48.5021 54.793C49.2487 56.2866 49.8459 57.7801 49.9953 59.423C49.9953 59.423 49.9953 59.423 49.9953 59.5724C44.6197 59.5724 40.1401 55.0917 40.1401 49.7149C40.2895 44.1887 44.7691 39.8574 50.1446 39.8574Z"
                fill={theme.texto}
              />
              <path
                d="M30.4346 19.8437C30.7332 17.7527 31.4798 15.6617 32.8237 14.0188C34.3169 11.9279 36.4074 10.733 39.0951 10.4343C39.3938 10.4343 39.8417 10.4343 40.1404 10.4343C38.0499 10.4343 36.2581 9.83687 34.6155 8.49267C33.5703 7.59654 32.6744 6.55105 32.0771 5.20685C31.3305 3.71329 30.8825 2.21973 30.5839 0.576816C30.5839 0.576816 30.5839 0.576816 30.5839 0.42746C35.9594 0.42746 40.439 4.90813 40.439 10.2849C40.439 15.6618 36.1087 20.1424 30.5839 20.1424C30.4346 19.9931 30.4346 19.9931 30.4346 19.8437Z"
                fill={theme.texto}
              />
              <path
                d="M30.4346 0.42749C30.4346 0.576846 30.4346 0.875558 30.2853 1.02491C29.8373 2.96654 29.24 4.75881 28.1948 6.40172C26.5523 8.64206 24.3125 9.98626 21.4754 10.285C21.1768 10.285 21.0275 10.285 20.7288 10.285C22.3713 10.285 24.0139 10.733 25.3577 11.6292C26.8509 12.5253 27.8962 13.8695 28.7921 15.3631C29.5387 16.8566 30.136 18.3502 30.2853 19.9931C30.2853 19.9931 30.2853 19.9931 30.2853 20.1425C24.9098 20.1425 20.4302 15.6618 20.4302 10.285C20.5795 4.75881 25.0591 0.42749 30.4346 0.42749Z"
                fill={theme.texto}
              />
              <path
                d="M30.4346 39.5587C30.7332 37.4678 31.4798 35.3768 32.8237 33.7339C34.3169 31.6429 36.4074 30.448 39.0951 30.1493C39.3938 30.1493 39.8417 30.1493 40.1404 30.1493C38.0499 30.1493 36.2581 29.5519 34.6155 28.2077C33.5703 27.3116 32.6744 26.2661 32.0771 24.9219C31.3305 23.4283 30.8825 21.9348 30.5839 20.2918C30.5839 20.2918 30.5839 20.2918 30.5839 20.1425C35.9594 20.1425 40.439 24.6232 40.439 30C40.439 35.3768 36.1087 39.8575 30.5839 39.8575C30.4346 39.7081 30.4346 39.7081 30.4346 39.5587Z"
                fill={theme.texto}
              />
              <path
                d="M30.4346 20.1424C30.4346 20.2918 30.4346 20.5905 30.2853 20.7398C29.8373 22.6815 29.24 24.4737 28.1948 26.1167C26.5523 28.357 24.3125 29.7012 21.4754 29.9999C21.1768 29.9999 21.0275 29.9999 20.7288 29.9999C22.3713 29.9999 24.0139 30.448 25.3577 31.3441C26.8509 32.2402 27.8962 33.5844 28.7921 35.078C29.5387 36.5716 30.136 38.0651 30.2853 39.708C30.2853 39.708 30.2853 39.708 30.2853 39.8574C24.9098 39.8574 20.4302 35.3767 20.4302 29.9999C20.5795 24.4737 25.0591 20.1424 30.4346 20.1424Z"
                fill={theme.texto}
              />
              <path
                d="M30.4346 59.2737C30.7332 57.1827 31.4798 55.0917 32.8237 53.4488C34.3169 51.3578 36.4074 50.163 39.0951 49.8643C39.3938 49.8643 39.8417 49.8643 40.1404 49.8643C38.0499 49.8643 36.2581 49.2668 34.6155 47.9226C33.5703 47.0265 32.6744 45.981 32.0771 44.6368C31.3305 43.1433 30.8825 41.6497 30.5839 40.0068C30.5839 40.0068 30.5839 40.0068 30.5839 39.8574C35.9594 39.8574 40.439 44.3381 40.439 49.7149C40.439 55.0917 36.1087 59.5724 30.5839 59.5724C30.4346 59.423 30.4346 59.423 30.4346 59.2737Z"
                fill={theme.texto}
              />
              <path
                d="M30.4346 39.8574C30.4346 40.0068 30.4346 40.3055 30.2853 40.4548C29.8373 42.3965 29.24 44.1887 28.1948 45.8317C26.5523 48.072 24.3125 49.4162 21.4754 49.7149C21.1768 49.7149 21.0275 49.7149 20.7288 49.7149C22.3713 49.7149 24.0139 50.163 25.3577 51.0591C26.8509 51.9552 27.8962 53.2994 28.7921 54.793C29.5387 56.2866 30.136 57.7801 30.2853 59.423C30.2853 59.423 30.2853 59.423 30.2853 59.5724C24.9098 59.5724 20.4302 55.0917 20.4302 49.7149C20.5795 44.1887 25.0591 39.8574 30.4346 39.8574Z"
                fill={theme.texto}
              />
              <path
                d="M10.7241 19.8437C11.0228 17.7528 11.7694 15.6618 13.1132 14.0189C14.6064 11.9279 16.6969 10.733 19.3847 10.4343C19.6833 10.4343 20.1313 10.4343 20.4299 10.4343C18.3394 10.4343 16.5476 9.83691 14.9051 8.4927C13.8598 7.59657 12.9639 6.55108 12.3666 5.20688C11.6201 3.71332 11.1721 2.21976 10.8735 0.576846C10.8735 0.576846 10.8735 0.576846 10.8735 0.42749C16.249 0.42749 20.7286 4.90816 20.7286 10.285C20.7286 15.6618 16.3983 20.1425 10.8735 20.1425C10.7241 19.9931 10.7241 19.9931 10.7241 19.8437Z"
                fill={theme.texto}
              />
              <path
                d="M10.7242 0.427521C10.7242 0.576877 10.7242 0.875588 10.5749 1.02494C10.1269 2.96657 9.5296 4.75884 8.48436 6.40175C6.84184 8.64209 4.60204 9.98629 1.76496 10.285C1.46632 10.285 1.317 10.285 1.01836 10.285C2.66088 10.285 4.30341 10.7331 5.64729 11.6292C7.14049 12.5253 8.18573 13.8695 9.08165 15.3631C9.82825 16.8567 10.4255 18.3502 10.5749 19.9931C10.5749 19.9931 10.5749 19.9931 10.5749 20.1425C5.19934 20.1425 0.719727 15.6618 0.719727 10.285C0.869047 4.75884 5.19932 0.427521 10.7242 0.427521Z"
                fill={theme.texto}
              />
              <path
                d="M10.7241 39.5587C11.0228 37.4678 11.7694 35.3768 13.1132 33.7339C14.6064 31.6429 16.6969 30.448 19.3847 30.1493C19.6833 30.1493 20.1313 30.1493 20.4299 30.1493C18.3394 30.1493 16.5476 29.5519 14.9051 28.2077C13.8598 27.3116 12.9639 26.2661 12.3666 24.9219C11.6201 23.4283 11.1721 21.9348 10.8735 20.2918C10.8735 20.2918 10.8735 20.2918 10.8735 20.1425C16.249 20.1425 20.7286 24.6232 20.7286 30C20.7286 35.3768 16.3983 39.8575 10.8735 39.8575C10.7241 39.7081 10.7241 39.7081 10.7241 39.5587Z"
                fill={theme.texto}
              />
              <path
                d="M10.7242 20.1424C10.7242 20.2918 10.7242 20.5905 10.5749 20.7398C10.1269 22.6815 9.5296 24.4737 8.48436 26.1167C6.84184 28.357 4.60204 29.7012 1.76496 29.9999C1.46632 29.9999 1.317 29.9999 1.01836 29.9999C2.66088 29.9999 4.30341 30.448 5.64729 31.3441C7.14049 32.2402 8.18573 33.5844 9.08165 35.078C9.82825 36.5716 10.4255 38.0651 10.5749 39.708C10.5749 39.708 10.5749 39.708 10.5749 39.8574C5.19934 39.8574 0.719727 35.3767 0.719727 29.9999C0.869047 24.4737 5.19932 20.1424 10.7242 20.1424Z"
                fill={theme.texto}
              />
              <path
                d="M10.7241 59.2737C11.0228 57.1827 11.7694 55.0917 13.1132 53.4488C14.6064 51.3578 16.6969 50.163 19.3847 49.8643C19.6833 49.8643 20.1313 49.8643 20.4299 49.8643C18.3394 49.8643 16.5476 49.2668 14.9051 47.9226C13.8598 47.0265 12.9639 45.981 12.3666 44.6368C11.6201 43.1433 11.1721 41.6497 10.8735 40.0068C10.8735 40.0068 10.8735 40.0068 10.8735 39.8574C16.249 39.8574 20.7286 44.3381 20.7286 49.7149C20.7286 55.0917 16.3983 59.5724 10.8735 59.5724C10.7241 59.423 10.7241 59.423 10.7241 59.2737Z"
                fill={theme.texto}
              />
              <path
                d="M10.7242 39.8574C10.7242 40.0068 10.7242 40.3055 10.5749 40.4548C10.1269 42.3965 9.5296 44.1887 8.48436 45.8317C6.84184 48.072 4.60204 49.4162 1.76496 49.7149C1.46632 49.7149 1.317 49.7149 1.01836 49.7149C2.66088 49.7149 4.30341 50.163 5.64729 51.0591C7.14049 51.9552 8.18573 53.2994 9.08165 54.793C9.82825 56.2866 10.4255 57.7801 10.5749 59.423C10.5749 59.423 10.5749 59.423 10.5749 59.5724C5.19934 59.5724 0.719727 55.0917 0.719727 49.7149C0.869047 44.1887 5.19932 39.8574 10.7242 39.8574Z"
                fill={theme.texto}
              />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
          >
            <mask
              id="mask0_358_146"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="60"
              height="60"
            >
              <path
                d="M29.5654 59.5724C45.8939 59.5724 59.1307 46.3324 59.1307 30C59.1307 13.6676 45.8939 0.427551 29.5654 0.427551C13.2369 0.427551 0 13.6676 0 30C0 46.3324 13.2369 59.5724 29.5654 59.5724Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_358_146)">
              <g style={{ mixBlendMode: "multiply" }}>
                <path
                  d="M29.5656 44.4875C37.5649 44.4875 44.0496 38.0012 44.0496 30C44.0496 21.9987 37.5649 15.5125 29.5656 15.5125C21.5663 15.5125 15.0815 21.9987 15.0815 30C15.0815 38.0012 21.5663 44.4875 29.5656 44.4875Z"
                  fill={theme.nombre === "dark" ? theme.fondo : theme.texto}
                />
              </g>
              <path
                opacity="0.7"
                d="M14.484 59.5725C22.4833 59.5725 28.9681 53.0862 28.9681 45.085C28.9681 37.0837 22.4833 30.5974 14.484 30.5974C6.48472 30.5974 0 37.0837 0 45.085C0 53.0862 6.48472 59.5725 14.484 59.5725Z"
                fill={theme.texto}
              />
              <path
                opacity="0.7"
                d="M44.6466 59.5725C52.6459 59.5725 59.1307 53.0862 59.1307 45.085C59.1307 37.0837 52.6459 30.5974 44.6466 30.5974C36.6473 30.5974 30.1626 37.0837 30.1626 45.085C30.1626 53.0862 36.6473 59.5725 44.6466 59.5725Z"
                fill={theme.texto}
              />
              <path
                opacity="0.7"
                d="M14.484 29.5519C22.4833 29.5519 28.9681 23.0656 28.9681 15.0644C28.9681 7.06315 22.4833 0.576874 14.484 0.576874C6.48472 0.576874 0 7.06315 0 15.0644C0 23.0656 6.48472 29.5519 14.484 29.5519Z"
                fill={theme.texto}
              />
              <path
                opacity="0.7"
                d="M44.6466 29.5519C52.6459 29.5519 59.1307 23.0656 59.1307 15.0644C59.1307 7.06318 52.6459 0.576904 44.6466 0.576904C36.6473 0.576904 30.1626 7.06318 30.1626 15.0644C30.1626 23.0656 36.6473 29.5519 44.6466 29.5519Z"
                fill={theme.texto}
              />
              <path
                opacity="0.7"
                d="M14.484 59.5725C22.4833 59.5725 28.9681 53.0862 28.9681 45.085C28.9681 37.0837 22.4833 30.5974 14.484 30.5974C6.48472 30.5974 0 37.0837 0 45.085C0 53.0862 6.48472 59.5725 14.484 59.5725Z"
                fill={theme.texto}
              />
              <path
                opacity="0.7"
                d="M44.6466 59.5725C52.6459 59.5725 59.1307 53.0862 59.1307 45.085C59.1307 37.0837 52.6459 30.5974 44.6466 30.5974C36.6473 30.5974 30.1626 37.0837 30.1626 45.085C30.1626 53.0862 36.6473 59.5725 44.6466 59.5725Z"
                fill={theme.texto}
              />
              <path
                opacity="0.7"
                d="M14.484 29.5519C22.4833 29.5519 28.9681 23.0656 28.9681 15.0644C28.9681 7.06315 22.4833 0.576874 14.484 0.576874C6.48472 0.576874 0 7.06315 0 15.0644C0 23.0656 6.48472 29.5519 14.484 29.5519Z"
                fill={theme.texto}
              />
              <path
                opacity="0.7"
                d="M44.6466 29.5519C52.6459 29.5519 59.1307 23.0656 59.1307 15.0644C59.1307 7.06318 52.6459 0.576904 44.6466 0.576904C36.6473 0.576904 30.1626 7.06318 30.1626 15.0644C30.1626 23.0656 36.6473 29.5519 44.6466 29.5519Z"
                fill={theme.texto}
              />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
          >
            <mask
              id="mask0_358_160"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="60"
              height="60"
            >
              <path
                d="M29.6959 0.427555C13.3674 0.427555 0.130512 13.6676 0.130512 30C0.130512 46.3324 13.3674 59.5724 29.6959 59.5724C46.0244 59.5724 59.2612 46.3324 59.2612 30C59.2612 13.6676 46.0244 0.427555 29.6959 0.427555Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_358_160)">
              <path
                d="M0.130512 0.427616C32.8316 0.427616 59.2612 26.8636 59.2612 59.5725V0.427616H0.130512Z"
                fill={theme.texto}
              />
              <path
                d="M50.7498 38.2146C47.9127 31.643 44.0304 25.8181 38.9535 20.74C33.8766 15.6619 28.0532 11.7787 21.4831 8.9409C14.7637 6.10314 7.447 4.60958 0.130325 4.60958V9.68768C6.84972 9.68768 13.4198 11.0319 19.5419 13.5709C25.5147 16.11 30.7409 19.6945 35.3698 24.3245C39.9988 28.9546 43.5824 34.182 46.1209 40.1563C48.6593 46.2798 50.0032 52.8515 50.0032 59.5725H55.0801C55.0801 52.1047 53.5869 44.9356 50.7498 38.2146Z"
                fill={theme.texto}
              />
              <path
                d="M41.7909 41.9485C39.5511 36.5717 36.2661 31.643 32.0851 27.6104C27.9042 23.4284 23.1259 20.1426 17.7504 17.9022C12.2256 15.5125 6.25276 14.3177 0.130638 14.3177V19.3958C5.50616 19.3958 10.8817 20.4413 15.8092 22.5323C20.5875 24.6233 24.9178 27.461 28.6508 31.1949C32.3838 34.9288 35.2208 39.2601 37.3113 44.0395C39.4018 48.9682 40.447 54.1957 40.447 59.7219H45.5239C45.3746 53.4489 44.18 47.4747 41.7909 41.9485Z"
                fill={theme.texto}
              />
              <path
                d="M32.3836 45.9811C30.5918 41.7992 28.0534 38.0653 24.9176 34.7794C21.6326 31.4936 17.8996 28.9546 13.7186 27.3117C9.38836 25.5194 4.75943 24.6233 0.130508 24.6233V29.7014C4.16215 29.7014 8.04449 30.4481 11.7775 32.091C15.3612 33.5846 18.4969 35.8249 21.334 38.5133C24.0217 41.2017 26.2615 44.4876 27.7547 48.0721C29.2479 51.806 30.1438 55.6893 30.1438 59.7219H35.2207C35.2207 54.7931 34.3248 50.3124 32.3836 45.9811Z"
                fill={theme.texto}
              />
              <path
                d="M22.8269 50.0137C21.6323 47.0266 19.8405 44.4876 17.4514 42.0979C15.2116 39.8575 12.5238 38.0653 9.53743 36.7211C6.55103 35.3769 3.26596 34.7795 -0.0190811 34.7795V39.8575C2.66868 39.8575 5.20713 40.3056 7.59625 41.3511C9.98537 42.3966 12.0759 43.7408 13.8677 45.5331C15.6595 47.3253 17.1527 49.4163 18.0486 51.806C19.0939 54.1957 19.5418 56.7348 19.5418 59.4232H24.6187C24.768 56.2867 24.1708 53.0009 22.8269 50.0137Z"
                fill={theme.texto}
              />
              <path
                d="M13.868 53.7476C13.1214 51.9554 12.0761 50.3125 10.7322 48.9682C9.38835 47.624 7.74581 46.5786 5.95397 45.8318C4.16213 45.085 2.22098 44.6369 0.130505 44.6369V49.715C1.47438 49.715 2.81827 50.0137 4.01283 50.4618C5.20739 50.9099 6.25264 51.6567 7.14856 52.5528C8.04448 53.4489 8.79107 54.4944 9.23903 55.6893C9.68699 56.8841 9.98563 58.2283 9.98563 59.5725H15.0625C15.0625 57.6309 14.6146 55.5399 13.868 53.7476Z"
                fill={theme.texto}
              />
              <path
                d="M0.13065 59.5725H5.20752C5.20752 58.8257 5.05822 58.2283 4.75958 57.6309C4.46094 57.0335 4.16228 56.436 3.71432 55.988C3.26636 55.5399 2.66912 55.0918 2.07184 54.9425C1.47456 54.6438 0.72793 54.4944 0.13065 54.4944V59.5725Z"
                fill={theme.texto}
              />
            </g>
          </SVG>
          <SVG
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
          >
            <mask
              id="mask0_358_172"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="60"
              height="60"
            >
              <path
                d="M29.8266 59.5724C46.1551 59.5724 59.3919 46.3324 59.3919 30C59.3919 13.6676 46.1551 0.427551 29.8266 0.427551C13.4981 0.427551 0.26123 13.6676 0.26123 30C0.26123 46.3324 13.4981 59.5724 29.8266 59.5724Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_358_172)">
              <path
                d="M22.2114 29.9999C22.2114 34.1819 25.6458 37.6171 29.8268 37.6171C34.0077 37.6171 37.4421 34.1819 37.4421 29.9999C37.4421 25.818 34.0077 22.3828 29.8268 22.3828C25.6458 22.3828 22.2114 25.818 22.2114 29.9999ZM33.1118 29.9999C33.1118 31.7922 31.6186 33.2858 29.8268 33.2858C28.0349 33.2858 26.5417 31.7922 26.5417 29.9999C26.5417 28.2077 28.0349 26.7141 29.8268 26.7141C31.6186 26.7141 33.1118 28.2077 33.1118 29.9999Z"
                fill={theme.texto}
              />
              <path
                d="M13.4014 29.9999C13.4014 39.1106 20.718 46.4291 29.8266 46.4291C38.9351 46.4291 46.2518 39.1106 46.2518 29.9999C46.2518 20.8892 38.9351 13.5708 29.8266 13.5708C20.8674 13.5708 13.4014 20.8892 13.4014 29.9999ZM41.9215 29.9999C41.9215 36.5716 36.546 42.0978 29.8266 42.0978C23.1072 42.0978 17.7316 36.7209 17.7316 29.9999C17.7316 23.2789 23.1072 17.9021 29.8266 17.9021C36.546 17.9021 41.9215 23.4283 41.9215 29.9999Z"
                fill={theme.texto}
              />
              <path
                d="M29.8267 9.23948C41.3243 9.23948 50.5821 18.4995 50.5821 29.9999C50.5821 41.5003 41.3243 50.7604 29.8267 50.7604V59.5724C46.1025 59.5724 59.392 46.2797 59.392 29.9999C59.392 13.7202 46.1025 0.42749 29.8267 0.42749V9.23948Z"
                fill={theme.texto}
              />
            </g>
          </SVG>
          <SVG
            mlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
          >
            <mask
              id="mask0_358_180"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="60"
              height="60"
            >
              <path
                d="M29.9571 59.5724C13.6286 59.5724 0.391788 46.3324 0.391788 30C0.391788 13.6676 13.6286 0.427551 29.9571 0.427551C46.2856 0.427551 59.5225 13.6676 59.5225 30C59.5225 46.3324 46.2856 59.5724 29.9571 59.5724Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_358_180)">
              <path
                d="M37.5723 29.9999C37.5723 34.1819 34.1379 37.6171 29.9569 37.6171C25.776 37.6171 22.3416 34.1819 22.3416 29.9999C22.3416 25.818 25.776 22.3828 29.9569 22.3828C34.1379 22.3828 37.5723 25.818 37.5723 29.9999ZM26.6719 29.9999C26.6719 31.7922 28.1651 33.2858 29.9569 33.2858C31.7488 33.2858 33.242 31.7922 33.242 29.9999C33.242 28.2077 31.7488 26.7141 29.9569 26.7141C28.1651 26.7141 26.6719 28.2077 26.6719 29.9999Z"
                fill={theme.texto}
              />
              <path
                d="M46.3823 29.9999C46.3823 39.1106 39.0656 46.4291 29.9571 46.4291C20.8486 46.4291 13.5319 39.1106 13.5319 29.9999C13.5319 20.8892 20.8486 13.5708 29.9571 13.5708C38.9163 13.5708 46.3823 20.8892 46.3823 29.9999ZM17.8622 29.9999C17.8622 36.5716 23.2377 42.0978 29.9571 42.0978C36.6765 42.0978 42.0521 36.7209 42.0521 29.9999C42.0521 23.2789 36.6765 17.9021 29.9571 17.9021C23.2377 17.9021 17.8622 23.4283 17.8622 29.9999Z"
                fill={theme.texto}
              />
              <path
                d="M29.957 9.23948C18.4594 9.23948 9.20156 18.4995 9.20156 29.9999C9.20156 41.5003 18.4594 50.7604 29.957 50.7604V59.5724C13.6812 59.5724 0.391695 46.2797 0.391695 29.9999C0.391695 13.7202 13.6812 0.42749 29.957 0.42749V9.23948Z"
                fill={theme.texto}
              />
            </g>
          </SVG>
        </Content>
      </Container>
    </StyledContainer>
  );
}

export default Valores;
