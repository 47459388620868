import React from "react";
import styled, { useTheme } from "styled-components";
import { breakpoints } from "../../components/base/breakpoints";

function Elia() {
  const theme = useTheme();

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="134"
      height="74"
      viewBox="0 0 134 74"
      fill="none"
    >
      <g clipPath="url(#clip0_76_3464)">
        <path
          d="M66.0317 17.8413V14.3884C66.0317 13.2763 66.0317 13.2653 67.0711 12.9299C70.7323 11.7486 74.5773 11.2287 78.4234 11.3949C79.5413 11.4517 80.6496 11.629 81.7289 11.9236C84.9021 12.7986 86.6209 14.9645 87.0543 18.1622C87.1638 19.0086 87.2178 19.8611 87.2159 20.7145C87.2159 25.0498 87.2159 29.3851 87.2159 33.7204C87.2159 35.095 87.7337 35.631 89.1294 35.6784C89.4967 35.6784 89.897 35.6784 90.3597 35.6784V38.5552C90.3597 39.5141 90.3597 39.5251 89.4783 39.8459C87.546 40.5877 85.4535 40.8214 83.4036 40.5241C81.7252 40.2652 80.4911 39.4303 79.8888 37.7859C79.7309 37.3592 79.5546 37.4468 79.3122 37.7166C76.2932 41.1002 70.5638 41.4867 67.1922 39.6126C64.4561 38.0921 63.3469 35.6711 63.424 32.6375C63.5159 29.0569 65.5946 26.9895 68.8193 25.9795C70.9715 25.305 73.2265 24.8857 75.4301 24.3935C76.4475 24.1637 77.4795 23.9887 78.5005 23.7627C78.6401 23.7335 78.8678 23.5512 78.8678 23.4455C78.8815 22.3376 78.8325 21.2298 78.7209 20.1274C78.5262 18.6398 77.4832 17.9069 76.0765 17.6335C74.2402 17.2688 72.4038 17.3782 70.5418 17.4876C69.0396 17.5496 67.5669 17.71 66.0317 17.8413ZM78.8605 27.686C77.2849 27.8391 75.7827 27.912 74.31 28.1527C73.7042 28.2357 73.1416 28.5104 72.7056 28.936C72.2696 29.3616 71.9835 29.9155 71.8897 30.5154C71.6782 31.4784 71.6958 32.4772 71.9411 33.4323C72.057 34.0076 72.3531 34.5318 72.7871 34.93C73.2212 35.3281 73.7709 35.5799 74.3577 35.6492C75.0747 35.7718 75.8094 35.7477 76.5168 35.5784C77.2241 35.4091 77.8891 35.0982 78.4712 34.6647C78.6028 34.5849 78.7096 34.4704 78.7797 34.334C78.8497 34.1975 78.8802 34.0444 78.8678 33.8917C78.8458 32.3859 78.8678 30.8764 78.8678 29.3705L78.8605 27.686Z"
          fill={theme.texto}
        />
        <path
          d="M27.0938 26.9749H9.37661C9.44054 28.4459 9.79754 29.8896 10.427 31.2227C11.5766 33.5125 13.5525 34.6064 16.0316 34.8689C19.1176 35.1483 22.2284 34.7921 25.1693 33.8224L25.7826 33.6474C25.7826 35.0621 25.7826 36.4222 25.7826 37.7858C25.7826 37.9317 25.5586 38.1103 25.4154 38.2088C23.4518 39.3839 21.2649 40.1436 18.9918 40.4402C16.0564 40.9145 13.0535 40.7716 10.1773 40.0209C5.48721 38.7193 2.57108 35.6455 1.42152 30.9967C0.462945 27.1098 0.455586 23.2194 1.93569 19.4456C3.69492 14.9608 7.09952 12.4158 11.8226 11.5735C14.0481 11.1812 16.3298 11.2295 18.5364 11.7157C22.4074 12.5543 24.8314 14.9644 26.0544 18.6434C26.734 20.7945 27.094 23.0325 27.1232 25.2868C27.1048 25.8191 27.0938 26.3441 27.0938 26.9749ZM18.8375 22.6688C18.8375 21.2322 18.742 19.8868 18.2205 18.6143C17.1518 16.0036 13.5047 15.2416 11.4444 17.2141C9.81367 18.7747 9.60799 20.8603 9.37294 22.9897L18.8375 22.6688Z"
          fill={theme.texto}
        />
        <path
          d="M32.3936 6.29023C31.2918 6.0277 30.1679 5.78341 29.0882 5.50995C28.9449 5.47348 28.7209 5.29847 28.7209 5.18179C28.6952 3.92022 28.7209 2.65499 28.7209 1.37154L34.3916 0.744398C36.1251 0.554797 37.8611 0.370062 39.5995 0.190184C40.4258 0.102676 40.7821 0.423538 40.7821 1.2257C40.7821 12.3417 40.7821 23.4564 40.7821 34.5699C40.7821 35.1387 40.9437 35.3612 41.4909 35.456C42.2622 35.5909 43.0224 35.7987 43.779 36.0065C43.8592 36.0359 43.9307 36.0846 43.9871 36.1482C44.0435 36.2119 44.0831 36.2885 44.1022 36.3711C44.1279 37.5452 44.1022 38.7266 44.1022 39.9444H29.0074C29.0074 38.7156 29.0074 37.4905 29.0074 36.2654C29.0074 36.1743 29.2351 36.043 29.3747 36.0065C30.37 35.7549 31.3726 35.5252 32.3973 35.2773L32.3936 6.29023Z"
          fill={theme.texto}
        />
        <path
          d="M46.2838 12.62L51.8957 11.9929L57.122 11.4314C57.9557 11.3438 58.3266 11.6647 58.3266 12.4997C58.3266 19.9014 58.3266 27.3031 58.3266 34.7049C58.3266 35.1315 58.4111 35.3466 58.8812 35.4341C59.6672 35.5799 60.4421 35.7987 61.2134 36.0211C61.2933 36.0508 61.3647 36.0996 61.4211 36.1632C61.4775 36.2268 61.5171 36.3032 61.5366 36.3857C61.5623 37.5635 61.5366 38.7375 61.5366 39.959H46.5813C46.5813 38.7485 46.5813 37.5379 46.5813 36.3311C46.5813 36.2253 46.7649 36.0612 46.8935 36.0284C47.7676 35.8024 48.6527 35.5982 49.5378 35.3976C49.8059 35.3357 49.9455 35.2518 49.9455 34.9273C49.9455 29.2247 49.9455 23.5221 49.9455 17.8194C49.9455 17.7356 49.9271 17.6517 49.9161 17.5423C48.8547 17.2908 47.808 17.0282 46.7539 16.8131C46.3866 16.7402 46.2618 16.6235 46.2691 16.2443C46.2985 15.0411 46.2838 13.8378 46.2838 12.62Z"
          fill={theme.texto}
        />
        <path
          d="M101.929 36.2982C102.028 38.5333 100.092 40.7465 97.246 40.6736C96.6478 40.6802 96.0543 40.5669 95.5011 40.3407C94.9479 40.1144 94.4463 39.7797 94.0264 39.3566C93.6064 38.9336 93.2767 38.4308 93.0568 37.8784C92.837 37.326 92.7316 36.7352 92.7469 36.1414C92.7469 33.6948 94.8073 31.8025 97.426 31.8316C100.338 31.8644 102.076 34.2089 101.929 36.2982Z"
          fill={theme.texto}
        />
        <path
          d="M58.5507 4.02594C58.5507 6.74234 56.9604 8.47792 54.687 8.76597C53.3263 8.98438 51.9338 8.66073 50.8123 7.86537C50.2723 7.48346 49.8353 6.9755 49.5399 6.3863C49.2444 5.7971 49.0995 5.14476 49.118 4.48683C49.1365 3.82891 49.3179 3.18562 49.646 2.61371C49.9741 2.0418 50.439 1.55885 50.9996 1.20745C51.8255 0.655361 52.7951 0.353201 53.7906 0.337667C54.7862 0.322133 55.7648 0.593897 56.6078 1.11995C57.153 1.40139 57.6173 1.81567 57.9571 2.32395C58.2969 2.83223 58.5012 3.41787 58.5507 4.02594Z"
          fill={theme.texto}
        />
        <path
          d="M34.1529 54.7843C34.1563 54.9942 34.1329 55.2037 34.0831 55.4078H29.7236V54.8682H32.1476C32.6948 54.8682 32.8307 54.6348 32.8307 54.2192C32.8448 54.0748 32.8292 53.9292 32.785 53.791C32.7407 53.6528 32.6687 53.525 32.5733 53.4152C32.4778 53.3054 32.3609 53.216 32.2297 53.1524C32.0984 53.0888 31.9556 53.0523 31.8097 53.0451C31.2074 53.0451 30.572 53.6066 30.572 55.2328C30.572 56.6475 31.2808 57.3439 32.2798 57.3439C32.5666 57.3457 32.85 57.283 33.1089 57.1605C33.3678 57.038 33.5954 56.8589 33.7746 56.6366L34.1051 56.9246C33.8947 57.3439 33.5652 57.6927 33.1572 57.9279C32.7492 58.1632 32.2807 58.2745 31.8097 58.2482C30.2047 58.2482 29.1139 57.1798 29.1139 55.3969C29.0929 55.0212 29.1494 54.6453 29.2798 54.2921C29.4103 53.9389 29.612 53.6159 29.8727 53.3428C30.1333 53.0697 30.4474 52.8523 30.7956 52.7039C31.1438 52.5556 31.5189 52.4793 31.8978 52.4799C33.2494 52.4909 34.1529 53.4024 34.1529 54.7843Z"
          fill={theme.texto}
        />
        <path
          d="M36.9404 56.1589C36.9404 57.0157 37.2048 57.3074 37.7153 57.3074C38.0208 57.2696 38.3154 57.1707 38.5811 57.0165C38.8469 56.8623 39.0784 56.6561 39.2615 56.4105H39.6031V57.0777H38.9347L39.3019 56.8298C38.7033 57.7778 37.9357 58.2554 37.0983 58.2554C36.1507 58.2554 35.5337 57.7122 35.5484 56.2099L35.5888 53.1763L35.8937 53.468L34.8469 53.242V52.8008L36.8449 52.5601L36.9955 52.6804L36.9367 54.2811L36.9404 56.1589ZM39.144 58.2044L39.0155 56.8699L39.0559 53.1727L39.357 53.468L38.3323 53.242V52.8226L40.2899 52.5601L40.4368 52.6804L40.4037 54.2811V57.8726L39.8822 57.3804L41.0979 57.6356V58.1023L39.144 58.2044Z"
          fill={theme.texto}
        />
        <path
          d="M42.567 55.7068V55.1052C42.567 54.4817 42.567 54.19 42.5303 53.7379L41.726 53.6321V53.2092L43.6394 52.4799L43.8231 52.5966L43.9443 54.0077V55.6958C43.9443 56.4032 43.9443 57.5736 43.981 58.0841H42.5119C42.556 57.5846 42.567 56.4141 42.567 55.7068ZM41.8325 57.632L43.0077 57.4059H43.7423L44.9212 57.632V58.095H41.8435L41.8325 57.632ZM43.3015 54.19H44.0581L43.8378 54.3869C44.1206 53.1581 44.8698 52.4909 45.5529 52.4909C45.7511 52.4792 45.9475 52.5334 46.1112 52.645C46.2748 52.7566 46.3963 52.9192 46.4564 53.1071C46.4564 53.6722 46.1773 53.9858 45.7219 53.9858C45.5684 53.9819 45.4177 53.9439 45.2811 53.8745C45.1444 53.8051 45.0252 53.706 44.9322 53.5847L44.609 53.2201L45.1232 53.2493C44.8244 53.4406 44.5665 53.6884 44.3642 53.9787C44.1619 54.269 44.0192 54.596 43.9443 54.9411L43.3052 54.901L43.3015 54.19Z"
          fill={theme.texto}
        />
        <path
          d="M46.7572 55.375C46.7572 53.5519 48.039 52.4908 49.5191 52.4908C50.9992 52.4908 52.2846 53.5446 52.2846 55.375C52.2846 57.2053 51.0579 58.2554 49.5191 58.2554C47.9802 58.2554 46.7572 57.1908 46.7572 55.375ZM50.7972 55.375C50.7972 53.8035 50.3454 53.0341 49.5264 53.0341C48.7074 53.0341 48.2593 53.8035 48.2593 55.375C48.2593 56.9465 48.6743 57.7012 49.5191 57.7012C50.3638 57.7012 50.7898 56.972 50.7898 55.3859L50.7972 55.375Z"
          fill={theme.texto}
        />
        <path
          d="M53.7721 55.1125C53.7721 54.4853 53.7721 54.19 53.739 53.7379L52.931 53.6321V53.2092L54.8372 52.4799L55.0171 52.5893L55.1016 53.5154L55.153 53.6139V57.1725L55.1163 57.2783V58.2555C55.1163 59.0139 55.1163 59.8342 55.153 60.7421H53.739C53.761 59.8342 53.7721 59.0284 53.7721 58.27V55.1125ZM56.0381 60.2827V60.7531H52.9677V60.2827L54.1614 60.053H54.8372L56.0381 60.2827ZM56.2475 53.3003C55.7333 53.3003 55.1897 53.7233 54.5874 54.3139L54.4846 54.1171C55.1457 53.0013 55.8986 52.4909 56.7947 52.4909C58.0251 52.4909 58.9983 53.5264 58.9983 55.3495C58.9983 57.1725 57.8965 58.2664 56.6001 58.2664C55.7333 58.2664 55.0759 57.8143 54.5213 56.6366L54.6241 56.4433C55.1567 57.1324 55.5974 57.4715 56.163 57.4715C56.9343 57.4715 57.5329 56.8298 57.5329 55.426C57.5329 54.0223 57.0077 53.3003 56.2475 53.3003Z"
          fill={theme.texto}
        />
        <path
          d="M64.9306 54.7843C64.9349 54.9945 64.9101 55.2043 64.8571 55.4078H60.5013V54.8682H62.9326C63.4835 54.8682 63.6158 54.6348 63.6158 54.2192C63.6304 54.075 63.6153 53.9294 63.5715 53.7913C63.5277 53.6531 63.456 53.5252 63.3609 53.4154C63.2657 53.3056 63.149 53.2161 63.018 53.1525C62.8869 53.0888 62.7441 53.0523 62.5984 53.0451C61.9961 53.0451 61.357 53.6066 61.357 55.2328C61.357 56.6475 62.0695 57.3439 63.0685 57.3439C63.3553 57.3457 63.6388 57.283 63.8977 57.1605C64.1566 57.038 64.3841 56.8589 64.5633 56.6366L64.8938 56.9246C64.6825 57.3438 64.3522 57.6925 63.9437 57.9277C63.5351 58.1629 63.0663 58.2743 62.5947 58.2482C60.9934 58.2482 59.899 57.1798 59.899 55.3969C59.8785 55.0211 59.9354 54.6451 60.0663 54.2918C60.1972 53.9386 60.3993 53.6156 60.6602 53.3426C60.9212 53.0696 61.2354 52.8523 61.5838 52.7039C61.9322 52.5556 62.3075 52.4794 62.6866 52.4799C64.0345 52.4909 64.9306 53.4024 64.9306 54.7843Z"
          fill={theme.texto}
        />
        <path
          d="M67.7949 55.0213C68.2723 54.8681 69.0399 54.6567 69.5982 54.5436V54.9848C69.0818 55.0988 68.5747 55.2512 68.0813 55.4406C67.3137 55.7469 67.086 56.1698 67.086 56.5891C67.0691 56.6995 67.0779 56.8122 67.1117 56.9186C67.1456 57.0251 67.2036 57.1224 67.2813 57.203C67.359 57.2837 67.4544 57.3456 67.5599 57.3839C67.6655 57.4222 67.7786 57.436 67.8904 57.4241C68.1989 57.4241 68.4045 57.3074 68.9444 56.9209L69.2897 56.6621L69.4292 56.8626L69.0216 57.2892C68.4009 57.9236 68.0299 58.2554 67.2623 58.2554C66.4029 58.2554 65.7455 57.7596 65.7455 56.8188C65.7455 56.0933 66.1421 55.5536 67.7949 55.0213ZM68.8967 57.1069V54.3869C68.8967 53.3258 68.6396 53.0414 67.8977 53.0414C67.5386 53.044 67.1824 53.1056 66.8436 53.2237L67.4717 52.8263L67.2807 53.7087C67.2182 54.2556 66.9428 54.4999 66.5682 54.4999C66.4141 54.5153 66.26 54.4701 66.1392 54.374C66.0183 54.2778 65.9402 54.1384 65.9218 53.9858C66.0577 53.0779 66.9465 52.4908 68.3458 52.4908C69.6753 52.4908 70.2519 53.0669 70.2519 54.5473V57.0267C70.2519 57.3913 70.3768 57.5371 70.5898 57.5371C70.6715 57.5321 70.7507 57.5068 70.82 57.4635C70.8893 57.4202 70.9466 57.3602 70.9865 57.2892L71.2546 57.5153C71.1593 57.7437 70.9943 57.9369 70.7829 58.0677C70.5715 58.1984 70.3242 58.2603 70.0756 58.2445C69.919 58.2587 69.7612 58.2395 69.6127 58.1881C69.4642 58.1367 69.3286 58.0544 69.2148 57.9466C69.101 57.8388 69.0118 57.7081 68.9531 57.5633C68.8945 57.4185 68.8677 57.2629 68.8746 57.1069H68.8967Z"
          fill={theme.texto}
        />
        <path
          d="M72.3454 58.095C72.3674 57.5846 72.3784 56.4141 72.3784 55.7068V55.1125C72.3784 54.4853 72.3784 54.19 72.3454 53.7379L71.5374 53.6321V53.2092L73.4545 52.4799L73.6381 52.5966L73.7593 53.8873V55.7104C73.7593 56.4178 73.7594 57.5882 73.7924 58.0987L72.3454 58.095ZM74.4535 57.632V58.095H71.6218V57.632L72.7824 57.4059H73.3406L74.4535 57.632ZM77.2264 54.5181V55.7068C77.2264 56.436 77.2264 57.5846 77.2594 58.095H75.8124C75.8344 57.5846 75.8454 56.436 75.8454 55.7068V54.6312C75.8454 53.7415 75.6177 53.4425 75.0485 53.4425C74.56 53.4425 74.0458 53.705 73.4949 54.252H73.179V53.6248H73.8585L73.3957 53.891C74.0679 52.9503 74.8648 52.4909 75.6692 52.4909C76.6571 52.4909 77.2264 53.1144 77.2264 54.5181ZM76.8004 57.4059L77.9352 57.632V58.095H75.1182V57.632L76.2641 57.4059H76.8004Z"
          fill={theme.texto}
        />
        <path
          d="M83.1431 49.7599L83.3157 49.862L83.2716 51.4663V55.7068C83.2716 56.5065 83.2827 57.3026 83.3047 58.095H81.8576C81.8797 57.3002 81.8907 56.5041 81.8907 55.7068V50.8246L81.0607 50.7115V50.2703L83.1431 49.7599ZM84.0686 57.632V58.095H81.0901V57.632L82.2837 57.4059H82.8309L84.0686 57.632Z"
          fill={theme.texto}
        />
        <path
          d="M86.7237 55.0213C87.2011 54.8681 87.9687 54.6567 88.527 54.5436V54.9848C88.0106 55.0988 87.5035 55.2512 87.0101 55.4406C86.2389 55.7469 86.0111 56.1698 86.0111 56.5891C85.9941 56.6998 86.0029 56.8129 86.037 56.9197C86.071 57.0264 86.1294 57.1239 86.2075 57.2046C86.2857 57.2854 86.3815 57.3472 86.4875 57.3852C86.5936 57.4233 86.7071 57.4366 86.8191 57.4241C87.1277 57.4241 87.3297 57.3074 87.8732 56.9209L88.2184 56.6621L88.358 56.8626L87.9503 57.2892C87.3297 57.9236 86.9587 58.2554 86.1911 58.2554C85.328 58.2554 84.6743 57.7596 84.6743 56.8188C84.6669 56.0933 85.0709 55.5536 86.7237 55.0213ZM87.8255 57.1069V54.3869C87.8255 53.3258 87.5684 53.0414 86.8228 53.0414C86.4649 53.044 86.11 53.1056 85.7724 53.2237L86.3968 52.8263L86.2095 53.7087C86.1434 54.2556 85.8716 54.4999 85.497 54.4999C85.3429 54.5153 85.1888 54.4701 85.068 54.374C84.9471 54.2778 84.869 54.1384 84.8506 53.9858C84.9865 53.0779 85.8716 52.4908 87.2746 52.4908C88.6041 52.4908 89.1807 53.0669 89.1807 54.5473V57.0267C89.1807 57.3913 89.3056 57.5371 89.5186 57.5371C89.6004 57.5321 89.6795 57.5068 89.7488 57.4635C89.8182 57.4202 89.8754 57.3602 89.9152 57.2892L90.1834 57.5153C90.0881 57.7437 89.9232 57.9369 89.7117 58.0677C89.5003 58.1984 89.253 58.2603 89.0044 58.2445C88.8487 58.2571 88.6921 58.2366 88.5449 58.1845C88.3978 58.1323 88.2635 58.0497 88.151 57.9422C88.0384 57.8346 87.9502 57.7045 87.8922 57.5605C87.8342 57.4165 87.8077 57.2619 87.8145 57.1069H87.8255Z"
          fill={theme.texto}
        />
        <path
          d="M91.2782 58.095C91.3003 57.5846 91.3113 56.4141 91.3113 55.7068V55.1125C91.3113 54.4853 91.3113 54.19 91.2782 53.7379L90.4702 53.6321V53.2092L92.3874 52.4799L92.571 52.5966L92.6922 53.8873V55.7104C92.6922 56.4178 92.6922 57.5882 92.7253 58.0987L91.2782 58.095ZM93.3827 57.632V58.095H90.5437V57.632L91.7042 57.4059H92.2625L93.3827 57.632ZM96.1556 54.5181V55.7068C96.1556 56.436 96.1556 57.5846 96.1923 58.095H94.7453C94.7673 57.5846 94.7783 56.436 94.7783 55.7068V54.6312C94.7783 53.7415 94.5506 53.4425 93.9813 53.4425C93.4892 53.4425 92.9787 53.705 92.4278 54.252H92.1119V53.6248H92.7914L92.3286 53.891C93.0007 52.9503 93.7977 52.4909 94.602 52.4909C95.5863 52.4909 96.1519 53.1144 96.1519 54.5181H96.1556ZM95.7332 57.4059L96.8681 57.632V58.095H94.0328V57.632L95.1823 57.4059H95.7332Z"
          fill={theme.texto}
        />
        <path
          d="M101.815 59.1379C101.815 58.7186 101.547 58.3649 100.82 58.3649H99.5415C99.2366 58.3764 98.9318 58.3407 98.638 58.2591V58.2045C98.0541 58.0513 97.7823 57.7232 97.7823 57.271C97.7823 56.8189 98.1275 56.2939 98.8841 55.6813L99.1596 55.86C98.9328 56.0746 98.8008 56.3695 98.7923 56.6804C98.7923 56.9684 98.9465 57.1507 99.457 57.1507H100.882C102.373 57.1507 102.972 57.756 102.972 58.7113C102.972 59.7832 101.918 60.7531 99.8831 60.7531C98.1091 60.7531 97.4701 60.1479 97.4701 59.3931C97.4701 58.8352 97.8374 58.4013 98.8657 58.0076L98.9759 58.0951C98.8264 58.2289 98.7063 58.3919 98.6229 58.5738C98.5395 58.7557 98.4946 58.9526 98.4911 59.1525C98.4911 59.8635 99.0236 60.1552 99.9932 60.1552C101.194 60.1552 101.815 59.7067 101.815 59.1379ZM99.9455 52.4909C101.348 52.4909 102.149 53.18 102.149 54.3432C102.149 55.5063 101.319 56.1662 99.9198 56.1662C98.5205 56.1662 97.6941 55.4735 97.6941 54.3432C97.6941 53.2128 98.5425 52.4909 99.9455 52.4909ZM98.9906 54.3432C98.9906 55.1599 99.3101 55.6886 99.9271 55.6886C100.544 55.6886 100.919 55.138 100.919 54.314C100.919 53.4899 100.577 52.9868 99.9639 52.9868C99.3505 52.9868 98.9906 53.5228 98.9906 54.3432ZM102.873 52.4763L103.049 52.593V53.3842H101.385V53.0451L102.873 52.4763Z"
          fill={theme.texto}
        />
        <path
          d="M105.572 56.1589C105.572 57.0157 105.837 57.3074 106.347 57.3074C106.653 57.2701 106.947 57.1712 107.213 57.017C107.479 56.8628 107.71 56.6564 107.893 56.4105H108.235V57.0777H107.566L107.934 56.8298C107.335 57.7778 106.564 58.2554 105.73 58.2554C104.779 58.2554 104.162 57.7122 104.18 56.2099L104.217 53.1763L104.522 53.468L103.475 53.242V52.8008L105.477 52.5601L105.624 52.6804L105.565 54.2811L105.572 56.1589ZM107.776 58.2044L107.647 56.8699L107.688 53.1727L107.992 53.468L106.964 53.242V52.8226L108.922 52.5601L109.072 52.6804L109.035 54.2811V57.8726L108.514 57.3804L109.73 57.6356V58.1023L107.776 58.2044Z"
          fill={theme.texto}
        />
        <path
          d="M112.54 55.0213C113.017 54.8681 113.785 54.6567 114.339 54.5436V54.9848C113.824 55.0982 113.318 55.2506 112.826 55.4406C112.055 55.7469 111.827 56.1698 111.827 56.5891C111.81 56.6998 111.819 56.8129 111.853 56.9197C111.887 57.0264 111.945 57.1239 112.023 57.2046C112.102 57.2854 112.197 57.3472 112.303 57.3852C112.41 57.4233 112.523 57.4366 112.635 57.4241C112.944 57.4241 113.146 57.3074 113.685 56.9209L114.053 56.6621L114.192 56.8626L113.785 57.2892C113.164 57.9236 112.789 58.2554 112.025 58.2554C111.162 58.2554 110.505 57.7596 110.505 56.8188C110.487 56.0933 110.887 55.5536 112.54 55.0213ZM113.641 57.1069V54.3869C113.641 53.3258 113.384 53.0414 112.639 53.0414C112.281 53.0438 111.926 53.1054 111.588 53.2237L112.213 52.8263L112.025 53.7087C111.959 54.2556 111.687 54.4999 111.291 54.4999C111.137 54.5153 110.983 54.4701 110.862 54.374C110.741 54.2778 110.663 54.1384 110.644 53.9858C110.784 53.0779 111.669 52.4908 113.072 52.4908C114.398 52.4908 114.975 53.0669 114.975 54.5473V57.0267C114.975 57.3913 115.103 57.5371 115.316 57.5371C115.474 57.5371 115.581 57.4569 115.713 57.2892L115.981 57.5153C115.885 57.7444 115.72 57.938 115.508 58.0688C115.296 58.1996 115.047 58.2611 114.798 58.2445C114.644 58.2538 114.49 58.231 114.346 58.1774C114.201 58.1238 114.07 58.0407 113.96 57.9335C113.849 57.8263 113.763 57.6973 113.707 57.5549C113.65 57.4125 113.624 57.2599 113.63 57.1069H113.641Z"
          fill={theme.texto}
        />
        <path
          d="M120.572 59.1379C120.572 58.7186 120.304 58.3649 119.576 58.3649H118.298C117.993 58.3764 117.689 58.3407 117.395 58.2591V58.2045C116.807 58.0513 116.539 57.7232 116.539 57.271C116.539 56.8189 116.884 56.2939 117.641 55.6813L117.916 55.86C117.69 56.0746 117.558 56.3695 117.549 56.6804C117.549 56.9684 117.703 57.1507 118.214 57.1507H119.639C121.13 57.1507 121.729 57.756 121.729 58.7113C121.729 59.7832 120.675 60.7531 118.64 60.7531C116.866 60.7531 116.227 60.1479 116.227 59.3931C116.227 58.8352 116.613 58.4013 117.623 58.0076L117.733 58.0951C117.583 58.2289 117.463 58.3919 117.38 58.5738C117.296 58.7557 117.251 58.9526 117.248 59.1525C117.248 59.8635 117.78 60.1552 118.75 60.1552C119.944 60.1552 120.572 59.7067 120.572 59.1379ZM118.702 52.4909C120.105 52.4909 120.924 53.18 120.924 54.3432C120.924 55.5063 120.094 56.1662 118.695 56.1662C117.296 56.1662 116.469 55.4735 116.469 54.3432C116.469 53.2128 117.299 52.4909 118.702 52.4909ZM117.722 54.3432C117.722 55.1599 118.041 55.6886 118.658 55.6886C119.275 55.6886 119.65 55.138 119.65 54.314C119.65 53.4899 119.308 52.9868 118.695 52.9868C118.082 52.9868 117.722 53.5228 117.722 54.3432ZM121.604 52.4763L121.78 52.593V53.3842H120.116V53.0451L121.604 52.4763Z"
          fill={theme.texto}
        />
        <path
          d="M127.271 54.7843C127.275 54.9945 127.25 55.2043 127.197 55.4078H122.864V54.8682H125.295C125.846 54.8682 125.978 54.6348 125.978 54.2192C125.993 54.075 125.978 53.9295 125.934 53.7913C125.89 53.6531 125.818 53.5252 125.723 53.4154C125.628 53.3056 125.511 53.2161 125.38 53.1525C125.249 53.0888 125.106 53.0523 124.961 53.0451C124.355 53.0451 123.719 53.6066 123.719 55.2328C123.719 56.6475 124.432 57.3439 125.431 57.3439C125.713 57.3426 125.992 57.2791 126.246 57.158C126.501 57.037 126.725 56.8614 126.903 56.6439L127.234 56.9319C127.022 57.3509 126.692 57.6993 126.283 57.9344C125.875 58.1695 125.406 58.2811 124.935 58.2555C123.334 58.2555 122.239 57.1872 122.239 55.4042C122.218 55.0286 122.275 54.6526 122.405 54.2994C122.536 53.9462 122.737 53.6232 122.998 53.3501C123.259 53.077 123.573 52.8596 123.921 52.7113C124.269 52.5629 124.644 52.4867 125.023 52.4872C126.386 52.4909 127.271 53.4024 127.271 54.7843Z"
          fill={theme.texto}
        />
        <path
          d="M29.8006 70.4228C29.8226 69.9123 29.8373 68.7382 29.8373 68.0345V67.4949C29.8373 66.8677 29.8373 66.5177 29.8006 66.0619L28.9779 65.9598V65.5369L31.0493 64.8076L31.233 64.928L31.1999 66.5214V68.0272C31.1999 68.7309 31.1999 69.905 31.233 70.4155L29.8006 70.4228ZM31.9087 69.956V70.4228H29.0697V69.956L30.2303 69.73H30.7812L31.9087 69.956ZM29.6353 62.9445C29.6258 62.7726 29.6684 62.6018 29.7576 62.4542C29.8469 62.3066 29.9787 62.1889 30.136 62.1165C30.2933 62.044 30.4688 62.0201 30.64 62.0477C30.8112 62.0754 30.97 62.1534 31.0961 62.2716C31.2221 62.3899 31.3095 62.5429 31.347 62.711C31.3845 62.8791 31.3704 63.0544 31.3064 63.2145C31.2425 63.3745 31.1316 63.5118 30.9882 63.6086C30.8448 63.7054 30.6755 63.7573 30.5021 63.7575C30.3907 63.7635 30.2793 63.7469 30.1746 63.7087C30.0699 63.6706 29.9741 63.6117 29.893 63.5356C29.8119 63.4596 29.7473 63.368 29.703 63.2663C29.6587 63.1647 29.6357 63.0552 29.6353 62.9445Z"
          fill={theme.texto}
        />
        <path
          d="M33.3298 70.4227C33.3519 69.9123 33.3666 68.7382 33.3666 68.0345V67.4365C33.3666 66.8094 33.3666 66.5177 33.3298 66.0619L32.5219 65.9598V65.5369L34.439 64.8076L34.6226 64.9279L34.7438 66.2187V68.0418C34.7438 68.7455 34.7438 69.9196 34.7769 70.43L33.3298 70.4227ZM35.438 69.956V70.4227H32.61V69.956L33.7669 69.7299H34.3251L35.438 69.956ZM38.2109 66.8458V68.0345C38.2109 68.7637 38.2109 69.9123 38.2439 70.4227H36.7969C36.8189 69.9123 36.8299 68.7601 36.8299 68.0345V66.9589C36.8299 66.0656 36.6022 65.7666 36.033 65.7666C35.5445 65.7666 35.034 66.0327 34.4794 66.576H34.1635V65.9525H34.8467L34.3802 66.215C35.056 65.278 35.8493 64.8149 36.6537 64.8149C37.6563 64.8149 38.2109 65.4384 38.2109 66.8458ZM37.7885 69.7336L38.9197 69.9597V70.4264H36.088V69.9597L37.2339 69.7336H37.7885Z"
          fill={theme.texto}
        />
        <path
          d="M42.5087 69.7737C43.0779 69.7737 43.4709 69.4456 43.9116 68.833L44.0328 69.0773C43.5297 70.0764 42.9127 70.5832 41.9541 70.5832C40.6062 70.5832 39.6439 69.4893 39.6439 67.7464C39.6439 66.0036 40.7751 64.8295 42.1267 64.8295C42.9494 64.8295 43.4709 65.2342 44.0328 66.1859L43.919 66.4411C43.3607 65.8541 42.9714 65.628 42.5197 65.628C41.7447 65.628 41.1094 66.288 41.1094 67.7392C41.1094 69.1028 41.697 69.7737 42.5087 69.7737ZM45.6488 69.956V70.4227L43.6472 70.5431L43.515 69.4492V65.8905L43.5664 65.7556V63.1377L42.6739 63.0247V62.5871L44.7674 62.073L44.94 62.1787L44.8959 63.7794V70.4118L44.4074 69.7336L45.6488 69.956Z"
          fill={theme.texto}
        />
        <path
          d="M48.1348 68.4829C48.1348 69.3434 48.3992 69.6315 48.9061 69.6315C49.2118 69.5942 49.5067 69.4958 49.7731 69.3423C50.0395 69.1888 50.2717 68.9833 50.4559 68.7382H50.7975V69.4054H50.1438L50.511 69.1538C49.9124 70.1018 49.1411 70.5831 48.3074 70.5831C47.3598 70.5831 46.7428 70.0362 46.7575 68.534L46.7979 65.504L47.1027 65.7957L46.056 65.566V65.1285L48.054 64.8878L48.2046 65.0045L48.1458 66.6052L48.1348 68.4829ZM50.3384 70.5284L50.2099 69.1939L50.2503 65.5004L50.5514 65.7957L49.5231 65.566V65.1467L51.4843 64.8878L51.6312 65.0045L51.5982 66.6052V70.2003L51.073 69.7044L52.2923 69.9633V70.4264L50.3384 70.5284Z"
          fill={theme.texto}
        />
        <path
          d="M53.1263 70.0982V68.7783H53.8939L54.1767 70.2113L53.5744 69.9706V69.5513C53.9861 69.8523 54.4845 70.0134 54.9958 70.0107C55.7634 70.0107 56.149 69.73 56.149 69.2414C56.149 68.8184 55.8735 68.5778 55.1353 68.3371L54.5624 68.1512C53.6993 67.8704 53.1778 67.3527 53.1778 66.5396C53.1778 65.6025 53.949 64.8149 55.3594 64.8149C55.96 64.8157 56.5495 64.9755 57.0672 65.278L57.0047 66.4776H56.2702L55.9911 65.1978L56.5163 65.4275V65.7921C56.1838 65.5316 55.7718 65.3914 55.3483 65.3947C54.7387 65.3947 54.3824 65.6609 54.3824 66.102C54.3824 66.5432 54.6101 66.7219 55.3961 66.9808L55.8478 67.123C56.9497 67.4876 57.4014 68.0564 57.4014 68.833C57.4014 69.8576 56.5383 70.5905 54.9958 70.5905C54.3394 70.5964 53.6935 70.4264 53.1263 70.0982Z"
          fill={theme.texto}
        />
        <path
          d="M60.6995 69.8321C60.9583 69.8174 61.2044 69.7159 61.3973 69.544L61.6691 69.8357C61.5089 70.0831 61.2845 70.2831 61.0195 70.4146C60.7545 70.5461 60.4588 70.6044 60.1633 70.5832C59.2488 70.5832 58.6942 70.1493 58.6942 69.1466C58.6942 68.8111 58.6942 68.5377 58.6942 68.0965V65.6645H57.8568V65.0775L59.2414 64.9024L58.6832 65.3181L59.2194 63.4039H60.1412L60.0825 65.4238V69.0226C60.0861 69.5841 60.3359 69.8321 60.6995 69.8321ZM61.4744 64.9936V65.6827H59.403V64.9936H61.4744Z"
          fill={theme.texto}
        />
        <path
          d="M62.8004 68.0345V67.4329C62.8004 66.8057 62.8004 66.5177 62.7674 66.0619L61.9594 65.9598V65.5369L63.8765 64.8076L64.0602 64.9279L64.1813 66.3354V68.0272C64.1813 68.7309 64.1813 69.905 64.2144 70.4154H62.7674C62.7894 69.9123 62.8004 68.7382 62.8004 68.0345ZM62.0659 69.956L63.2411 69.7299H63.9757L65.1509 69.956V70.4227H62.0732L62.0659 69.956ZM63.535 66.514H64.2915L64.0748 66.7109C64.3576 65.4822 65.1069 64.8149 65.7863 64.8149C65.9812 64.8086 66.1729 64.8652 66.3325 64.9763C66.4921 65.0875 66.6111 65.2471 66.6714 65.4311C66.6714 65.9963 66.3887 66.3135 65.9369 66.3135C65.7835 66.3094 65.6329 66.2712 65.4963 66.2018C65.3597 66.1324 65.2404 66.0335 65.1473 65.9124L64.8277 65.5478L65.3383 65.5733C64.7365 65.9607 64.3138 66.569 64.163 67.2651L63.5203 67.225L63.535 66.514Z"
          fill={theme.texto}
        />
        <path
          d="M66.891 72.3844C66.9374 72.1995 67.0487 72.037 67.2048 71.9261C67.361 71.8152 67.5518 71.7631 67.7431 71.7791C67.9223 71.7786 68.0989 71.8216 68.2576 71.9042C68.4163 71.9868 68.5524 72.1065 68.6539 72.2531L69.0212 72.6907L68.5841 72.9094L68.2536 72.6907C68.9249 72.2706 69.4195 71.6227 69.6455 70.8676L70.1267 69.5039L71.7573 64.9827H72.4441L70.2662 70.6342C69.5978 72.3807 69.0175 73.1866 68.0148 73.1866C67.4052 73.1902 66.913 72.8511 66.891 72.3844ZM67.9744 65.5989L66.8947 65.4238V64.9936H69.8953V65.4457L68.6943 65.6171L67.9744 65.5989ZM69.7814 71.0681L67.2546 64.9936H68.7824L70.4462 69.5222L69.7814 71.0681ZM71.7353 65.5989L70.6519 65.4275V64.9936H72.8555V65.4421L71.9594 65.6135L71.7353 65.5989Z"
          fill={theme.texto}
        />
        <path
          d="M77.9352 67.3454C78.4127 67.1922 79.1803 66.9808 79.7349 66.8677V67.3126C79.2199 67.426 78.7142 67.5771 78.2217 67.7647C77.4504 68.071 77.2227 68.4939 77.2227 68.9132C77.2087 69.0235 77.2196 69.1356 77.2547 69.2411C77.2898 69.3467 77.3482 69.4432 77.4256 69.5235C77.503 69.6038 77.5975 69.666 77.7022 69.7055C77.8069 69.745 77.9191 69.7609 78.0307 69.7518C78.3392 69.7518 78.5412 69.6315 79.0848 69.245L79.43 68.9862L79.5696 69.1903L79.1583 69.6169C78.5376 70.2477 78.1666 70.5832 77.399 70.5832C76.5359 70.5832 75.8822 70.0837 75.8822 69.1429C75.8859 68.421 76.2825 67.8777 77.9352 67.3454ZM79.0371 69.4346V66.7109C79.0371 65.6499 78.78 65.3655 78.0344 65.3655C77.6765 65.3681 77.3216 65.4297 76.984 65.5478L77.6084 65.1504L77.4211 66.0328C77.355 66.5797 77.0832 66.824 76.7086 66.824C76.5545 66.8394 76.4004 66.7942 76.2795 66.698C76.1587 66.6019 76.0806 66.4625 76.0622 66.3099C76.1981 65.4056 77.0832 64.8149 78.4861 64.8149C79.8157 64.8149 80.3923 65.391 80.3923 66.8714V69.369C80.3923 69.7336 80.5172 69.8795 80.7302 69.8795C80.8122 69.8738 80.8915 69.8478 80.9608 69.8038C81.0301 69.7599 81.0871 69.6994 81.1268 69.6279L81.3949 69.8539C81.3006 70.0831 81.1359 70.2769 80.9242 70.4078C80.7126 70.5387 80.4648 70.6001 80.216 70.5832C80.0593 70.5959 79.9017 70.5752 79.7538 70.5224C79.6058 70.4696 79.4709 70.386 79.3582 70.2772C79.2455 70.1685 79.1576 70.037 79.1003 69.8916C79.043 69.7463 79.0177 69.5905 79.026 69.4346H79.0371Z"
          fill={theme.texto}
        />
        <path
          d="M81.8724 70.0982V68.7783H82.64L82.9228 70.2113L82.3205 69.9706V69.5513C82.7324 69.8518 83.2307 70.0129 83.7418 70.0107C84.5094 70.0107 84.8987 69.73 84.8987 69.2414C84.8987 68.8184 84.6233 68.5778 83.8814 68.3371L83.3085 68.1512C82.449 67.8704 81.9239 67.3527 81.9239 66.5396C81.9239 65.6025 82.6988 64.8149 84.1275 64.8149C84.7269 64.8162 85.3151 64.976 85.8316 65.278L85.7692 66.4776H85.0346L84.7555 65.1978L85.2807 65.4275V65.7921C84.9493 65.5298 84.5366 65.3894 84.1128 65.3947C83.5031 65.3947 83.1505 65.6609 83.1505 66.102C83.1505 66.5432 83.3782 66.7219 84.1605 66.9808L84.6123 67.123C85.7141 67.4876 86.1658 68.0564 86.1658 68.833C86.1658 69.8576 85.3028 70.5905 83.7602 70.5905C83.0978 70.5984 82.4455 70.4283 81.8724 70.0982Z"
          fill={theme.texto}
        />
        <path
          d="M86.9594 70.0982V68.7783H87.727L88.0097 70.2113L87.4074 69.9706V69.5513C87.8193 69.8518 88.3176 70.0129 88.8288 70.0107C89.5964 70.0107 89.9857 69.73 89.9857 69.2414C89.9857 68.8184 89.7102 68.5778 88.972 68.3371L88.3954 68.1512C87.536 67.8704 87.0108 67.3527 87.0108 66.5396C87.0108 65.6025 87.7857 64.8149 89.196 64.8149C89.7955 64.8156 90.3839 64.9755 90.9002 65.278L90.8377 66.4776H90.1032L89.8241 65.1978L90.3493 65.4275V65.7921C90.0178 65.5298 89.6052 65.3894 89.1813 65.3947C88.5717 65.3947 88.2191 65.6609 88.2191 66.102C88.2191 66.5432 88.4468 66.7219 89.2291 66.9808L89.6808 67.123C90.7827 67.4876 91.2344 68.0564 91.2344 68.833C91.2344 69.8576 90.3713 70.5905 88.8288 70.5905C88.1725 70.5952 87.527 70.4252 86.9594 70.0982Z"
          fill={theme.texto}
        />
        <path
          d="M92.0131 67.6991C92.0131 65.876 93.2948 64.8149 94.7749 64.8149C96.2551 64.8149 97.5405 65.8687 97.5405 67.6991C97.5405 69.5294 96.3138 70.5832 94.7749 70.5832C93.2361 70.5832 92.0131 69.5149 92.0131 67.6991ZM96.053 67.6991C96.053 66.1312 95.5976 65.3582 94.7823 65.3582C93.9669 65.3582 93.5115 66.1312 93.5115 67.6991C93.5115 69.2669 93.9522 70.018 94.7823 70.018C95.6123 70.018 96.053 69.2851 96.053 67.71V67.6991Z"
          fill={theme.texto}
        />
        <path
          d="M99.8536 67.5459C99.8536 68.9169 100.551 69.668 101.572 69.668C101.857 69.6694 102.138 69.6008 102.39 69.4685C102.642 69.3362 102.857 69.1442 103.016 68.9096L103.354 69.1539C103.187 69.5995 102.878 69.9789 102.475 70.2349C102.071 70.4908 101.594 70.6092 101.117 70.5722C99.5487 70.5722 98.4102 69.5294 98.4102 67.7027C98.3907 67.3219 98.451 66.9412 98.5871 66.5847C98.7232 66.2281 98.9323 65.9034 99.2011 65.6311C99.4699 65.3588 99.7926 65.1447 100.149 65.0023C100.505 64.86 100.887 64.7924 101.271 64.804C102.373 64.804 103.236 65.4931 103.354 66.3682C103.331 66.5187 103.252 66.655 103.132 66.7497C103.012 66.8444 102.86 66.8905 102.707 66.8787C102.292 66.8787 102.028 66.6234 101.973 66.0765L101.778 65.1723L102.461 65.6171C102.134 65.4486 101.772 65.3587 101.403 65.3546C100.566 65.3582 99.8536 66.0583 99.8536 67.5459Z"
          fill={theme.texto}
        />
        <path
          d="M104.838 70.4228C104.86 69.9123 104.871 68.7382 104.871 68.0345V67.4949C104.871 66.8677 104.871 66.5177 104.838 66.0619L104.015 65.9598V65.5369L106.087 64.8076L106.27 64.928L106.237 66.5214V68.0272C106.237 68.7309 106.237 69.905 106.27 70.4155L104.838 70.4228ZM106.946 69.956V70.4228H104.107V69.956L105.264 69.73H105.818L106.946 69.956ZM104.673 62.9445C104.663 62.7726 104.706 62.6018 104.795 62.4542C104.884 62.3066 105.016 62.1889 105.173 62.1165C105.33 62.044 105.506 62.0201 105.677 62.0477C105.848 62.0754 106.007 62.1534 106.133 62.2716C106.259 62.3899 106.347 62.5429 106.384 62.711C106.422 62.8791 106.408 63.0544 106.344 63.2145C106.28 63.3745 106.169 63.5118 106.025 63.6086C105.882 63.7054 105.713 63.7573 105.539 63.7575C105.428 63.764 105.316 63.7478 105.211 63.7098C105.106 63.6719 105.01 63.613 104.929 63.5368C104.848 63.4607 104.783 63.3688 104.739 63.267C104.695 63.1651 104.672 63.0553 104.673 62.9445Z"
          fill={theme.texto}
        />
        <path
          d="M109.741 67.3454C110.218 67.1922 110.986 66.9808 111.544 66.8677V67.3126C111.028 67.4255 110.521 67.5766 110.027 67.7647C109.26 68.071 109.032 68.4939 109.032 68.9132C109.015 69.0238 109.023 69.137 109.056 69.2439C109.09 69.3508 109.148 69.4486 109.226 69.5297C109.303 69.6108 109.399 69.673 109.505 69.7115C109.611 69.7501 109.724 69.7639 109.836 69.7518C110.145 69.7518 110.351 69.6315 110.89 69.245L111.236 68.9862L111.375 69.1903L110.968 69.6169C110.347 70.2477 109.976 70.5832 109.208 70.5832C108.349 70.5832 107.692 70.0837 107.692 69.1429C107.692 68.421 108.088 67.8777 109.741 67.3454ZM110.843 69.4346V66.7109C110.843 65.6499 110.586 65.3655 109.844 65.3655C109.485 65.3681 109.128 65.4297 108.79 65.5478L109.418 65.1504L109.227 66.0328C109.164 66.5797 108.889 66.824 108.514 66.824C108.36 66.8394 108.206 66.7942 108.085 66.698C107.964 66.6019 107.886 66.4625 107.868 66.3099C108.004 65.4056 108.893 64.8149 110.292 64.8149C111.621 64.8149 112.198 65.391 112.198 66.8714V69.369C112.198 69.7336 112.323 69.8795 112.536 69.8795C112.618 69.8738 112.697 69.8478 112.766 69.8038C112.836 69.7599 112.893 69.6994 112.932 69.6279L113.201 69.8539C113.106 70.0831 112.942 70.2769 112.73 70.4078C112.518 70.5387 112.27 70.6001 112.022 70.5832C111.865 70.5959 111.707 70.5752 111.559 70.5224C111.411 70.4696 111.277 70.386 111.164 70.2772C111.051 70.1685 110.963 70.037 110.906 69.8916C110.849 69.7463 110.823 69.5905 110.832 69.4346H110.843Z"
          fill={theme.texto}
        />
        <path
          d="M116.165 69.8321C116.425 69.8168 116.672 69.7154 116.866 69.544L117.138 69.8357C116.978 70.0828 116.753 70.2826 116.488 70.4141C116.223 70.5456 115.928 70.604 115.632 70.5832C114.714 70.5832 114.163 70.1493 114.163 69.1466C114.163 68.8111 114.163 68.5377 114.163 68.0965V65.6645H113.315V65.0775L114.703 64.9024L114.145 65.3181L114.681 63.4039H115.603L115.544 65.4238V69.0226C115.555 69.5841 115.801 69.8321 116.165 69.8321ZM116.943 64.9936V65.6827H114.868V64.9936H116.943Z"
          fill={theme.texto}
        />
        <path
          d="M118.221 70.4228C118.244 69.9123 118.255 68.7382 118.255 68.0345V67.4949C118.255 66.8677 118.255 66.5177 118.221 66.0619L117.399 65.9598V65.5369L119.47 64.8076L119.654 64.928L119.621 66.5214V68.0272C119.621 68.7309 119.621 69.905 119.654 70.4155L118.221 70.4228ZM120.33 69.956V70.4228H117.491V69.956L118.648 69.73H119.191L120.33 69.956ZM118.056 62.9445C118.047 62.7726 118.089 62.6018 118.179 62.4542C118.268 62.3066 118.4 62.1889 118.557 62.1165C118.714 62.044 118.89 62.0201 119.061 62.0477C119.232 62.0754 119.391 62.1534 119.517 62.2716C119.643 62.3899 119.73 62.5429 119.768 62.711C119.805 62.8791 119.791 63.0544 119.727 63.2145C119.663 63.3745 119.552 63.5118 119.409 63.6086C119.266 63.7054 119.096 63.7573 118.923 63.7575C118.811 63.764 118.7 63.7478 118.595 63.7098C118.49 63.6719 118.394 63.613 118.313 63.5368C118.232 63.4607 118.167 63.3688 118.123 63.267C118.079 63.1651 118.056 63.0553 118.056 62.9445Z"
          fill={theme.texto}
        />
        <path
          d="M121.06 67.6991C121.06 65.876 122.342 64.8149 123.825 64.8149C125.309 64.8149 126.587 65.8687 126.587 67.6991C126.587 69.5294 125.364 70.5832 123.825 70.5832C122.287 70.5832 121.06 69.5149 121.06 67.6991ZM125.1 67.6991C125.1 66.1312 124.648 65.3582 123.833 65.3582C123.017 65.3582 122.562 66.1312 122.562 67.6991C122.562 69.2669 123.003 70.018 123.833 70.018C124.663 70.018 125.093 69.2851 125.093 67.71L125.1 67.6991Z"
          fill={theme.texto}
        />
        <path
          d="M128.134 70.4227C128.156 69.9123 128.167 68.7382 128.167 68.0345V67.4365C128.167 66.8094 128.167 66.5177 128.134 66.0619L127.326 65.9598V65.5369L129.239 64.8076L129.426 64.9279L129.544 66.2187V68.0418C129.544 68.7455 129.544 69.9196 129.581 70.43L128.134 70.4227ZM130.238 69.956V70.4227H127.41V69.956L128.571 69.7299H129.129L130.238 69.956ZM133.011 66.8458V68.0345C133.011 68.7637 133.011 69.9123 133.048 70.4227H131.579C131.604 69.9123 131.615 68.7601 131.615 68.0345V66.9589C131.615 66.0656 131.384 65.7666 130.815 65.7666C130.326 65.7666 129.816 66.0327 129.265 66.576H128.945V65.9525H129.628L129.166 66.215C129.838 65.278 130.635 64.8149 131.439 64.8149C132.445 64.8149 133.011 65.4384 133.011 66.8458ZM132.589 69.7336L133.723 69.9597V70.4264H130.888V69.9597L132.045 69.7336H132.589Z"
          fill={theme.texto}
        />
      </g>
      <defs>
        <clipPath id="clip0_76_3464">
          <rect
            width="133"
            height="73"
            fill="white"
            transform="translate(0.720093 0.190186)"
          />
        </clipPath>
      </defs>
    </SVG>
  );
}

export const SVG = styled.svg`
  width: 44.99px;
  height: 26.004px;

  & path {
    transition: 0.2s;
  }

  ${breakpoints.min_desktop} {
    width: 133px;
    height: 73px;
  }
`;

export default Elia;
