import React from "react";
import ScrollToTop from "react-scroll-to-top";
import { CorteDesktop, CorteMobile } from "../base/Cortes";
import Container from "../base/Container";
import {
  ContainerTop,
  Content,
  ContainerText,
  ContainerRedesFooter,
  Column,
  ColumnSecond,
  Arrow,
  ContainerRed,
  RedImage,
  StyledLink,
  ContainerMobile,
  ContainerDesktop,
  RowDesktop,
  ButtonHover,
} from "./FooterComp";
import LogoISO from "../../assets/Logo-ISO";
import LogoUSH from "../../assets/Logo-USH";
import { useTheme } from "styled-components";
import BotonSecundario from "../base/BotonSecundario";
import Valores from "../home/Valores";

function Footer() {
  const theme = useTheme();

  return (
    <Content>
      <Container>
        <ContainerTop>
          <div className="work">
            <Valores />
            <p className="dropdown-title-web mb-80 mt-80 body-bold-mobile mb-25-mobile mt-25-mobile text-center">
              If you are a linguist, translator, graphic designer or IT
              specialist, join our team!
            </p>
            <a
              href="https://cloud.protemos.com/vendor-app/create?token=2883715efa8d9747213570c8257c0ec3"
              target="_blank"
              rel="noreferrer"
            >
              <BotonSecundario className="mb-80 mb-25-mobile">
                Work with us
              </BotonSecundario>
            </a>
          </div>
          <ScrollToTop
            smooth
            component={
              <>
                <Arrow
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12.5 7.625L8 3.125L3.5 7.625M8 3.75V12.875"
                    stroke={theme.texto}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </Arrow>
                <span className="semibold-mobile label-form">Back to top</span>
              </>
            }
          />
        </ContainerTop>

        <ContainerMobile>
          <ContainerText>
            <p className="texto-regular txt-regular-mobile text-center-mobile text-left mb-25-mobile">
              We're committed to protecting your privacy. The information
              <CorteDesktop /> you provide will only be used to respond to your
              inquiry.
              <CorteDesktop />
              <CorteDesktop />
              Ushuaia Solutions is an ISO certified language services company
              <CorteDesktop /> with more than 20 years' experience. We have
              offices
              <CorteDesktop /> in Latin America and Europe.
            </p>
            <ContainerRedesFooter>
              {/* linkedin */}
              <ContainerRed
                href="https://www.linkedin.com/company/ushuaia-solutions/"
                target="_blank"
                aria-label="Linkedin"
              >
                <RedImage
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="27"
                  viewBox="0 0 26 27"
                  fill="none"
                  aria-label="Linkedin"
                >
                  <path
                    d="M26 7.47775C25.9948 5.6759 25.2766 3.94934 24.0025 2.67523C22.7284 1.40113 21.0019 0.683011 19.2 0.677734H6.80003C4.99818 0.683011 3.27165 1.40113 1.99754 2.67523C0.723438 3.94934 0.00530683 5.6759 3.05176e-05 7.47775V19.8777C0.00530683 21.6796 0.723438 23.4061 1.99754 24.6802C3.27165 25.9543 4.99818 26.6725 6.80003 26.6777H19.2C21.0019 26.6725 22.7284 25.9543 24.0025 24.6802C25.2766 23.4061 25.9948 21.6796 26 19.8777V7.47775ZM10.07 19.3377H7.55003V11.2477H10.07V19.3377ZM8.81004 10.1377C8.52086 10.1377 8.23819 10.0518 7.99788 9.89096C7.75757 9.73009 7.57046 9.50149 7.46025 9.23413C7.35005 8.96677 7.32171 8.6727 7.37886 8.38922C7.43601 8.10574 7.57605 7.8456 7.78123 7.64182C7.98642 7.43803 8.24752 7.29977 8.53139 7.24457C8.81526 7.18937 9.10912 7.21972 9.37572 7.33176C9.64232 7.4438 9.86964 7.63249 10.0289 7.8739C10.1881 8.11531 10.272 8.39857 10.27 8.68774C10.2714 8.87984 10.2345 9.07028 10.1616 9.24802C10.0887 9.42575 9.98122 9.58724 9.84538 9.72308C9.70954 9.85892 9.54805 9.96641 9.37032 10.0393C9.19258 10.1122 9.00214 10.1491 8.81004 10.1477V10.1377ZM19.48 19.3377H16.97V15.3977C16.97 14.4677 16.97 13.2578 15.67 13.2578C14.37 13.2578 14.16 14.2577 14.16 15.3377V19.3377H11.64V11.2477H14.06V12.3377C14.3029 11.9268 14.6522 11.5891 15.0712 11.3602C15.4901 11.1314 15.963 11.02 16.44 11.0378C18.98 11.0378 19.44 12.7077 19.44 14.8877L19.48 19.3377Z"
                    fill={theme.texto}
                  />
                </RedImage>
              </ContainerRed>
              {/* youtube */}
              <ContainerRed
                href="https://www.youtube.com/channel/UCexqpO701H7nI72dplSkjaw"
                target="_blank"
                aria-label="Youtube"
              >
                <RedImage
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  aria-label="Youtube"
                >
                  <path
                    d="M15.4433 13.5578L12.0133 11.6878C11.8733 11.6078 11.3933 11.6878 11.3933 11.8778V15.5078C11.3933 15.6678 11.8733 15.7678 12.0133 15.6978L15.5933 13.9178C15.7333 13.8478 15.5833 13.6378 15.4433 13.5578Z"
                    fill={theme.texto}
                  />
                  <path
                    d="M19.5234 0.677734H7.12337C5.32152 0.683011 3.59497 1.40113 2.32086 2.67523C1.04676 3.94934 0.328641 5.6759 0.323364 7.47775V19.8777C0.328641 21.6796 1.04676 23.4061 2.32086 24.6802C3.59497 25.9543 5.32152 26.6725 7.12337 26.6777H19.5234C21.3252 26.6725 23.0518 25.9543 24.3259 24.6802C25.6 23.4061 26.3181 21.6796 26.3234 19.8777V7.47775C26.3181 5.6759 25.6 3.94934 24.3259 2.67523C23.0518 1.40113 21.3252 0.683011 19.5234 0.677734ZM20.2834 15.4977C20.2847 15.9083 20.2049 16.3151 20.0487 16.6948C19.8925 17.0745 19.6629 17.4196 19.3731 17.7104C19.0832 18.0012 18.7388 18.2319 18.3596 18.3893C17.9805 18.5467 17.5739 18.6277 17.1634 18.6277H9.16336C8.75279 18.6277 8.34626 18.5467 7.96707 18.3893C7.58788 18.2319 7.24349 18.0012 6.95364 17.7104C6.66379 17.4196 6.43419 17.0745 6.27798 16.6948C6.12178 16.3151 6.04205 15.9083 6.04337 15.4977V11.7878C6.04337 10.9603 6.37207 10.1667 6.95718 9.58157C7.5423 8.99646 8.33589 8.66772 9.16336 8.66772H17.1634C17.9908 8.66772 18.7844 8.99646 19.3695 9.58157C19.9547 10.1667 20.2834 10.9603 20.2834 11.7878V15.4977Z"
                    fill={theme.texto}
                  />
                </RedImage>
              </ContainerRed>
              {/* instagram */}
              <ContainerRed
                href="https://www.instagram.com/ushuaiasolutions/"
                target="_blank"
                aria-label="Instagram"
              >
                <RedImage
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  aria-label="Instagram"
                >
                  <path
                    d="M13.7167 11.2676C13.2396 11.2656 12.7727 11.4053 12.3751 11.6689C11.9774 11.9325 11.667 12.3082 11.483 12.7484C11.2991 13.1886 11.2499 13.6735 11.3418 14.1416C11.4337 14.6098 11.6624 15.0401 11.999 15.3782C12.3357 15.7162 12.7651 15.9467 13.2329 16.0405C13.7006 16.1344 14.1857 16.0872 14.6267 15.9051C15.0676 15.723 15.4446 15.4141 15.7098 15.0176C15.9751 14.621 16.1167 14.1547 16.1167 13.6776C16.1167 13.0402 15.8642 12.4287 15.4144 11.977C14.9646 11.5253 14.3541 11.2702 13.7167 11.2676Z"
                    fill={theme.texto}
                  />
                  <path
                    d="M16.6966 7.48779H10.6066C9.77293 7.49044 8.97429 7.82349 8.38571 8.41394C7.79713 9.00439 7.46662 9.80408 7.46663 10.6378V16.7178C7.46662 17.5515 7.79713 18.3512 8.38571 18.9417C8.97429 19.5321 9.77293 19.8652 10.6066 19.8678H16.6966C17.5303 19.8652 18.329 19.5321 18.9176 18.9417C19.5061 18.3512 19.8366 17.5515 19.8366 16.7178V10.6378C19.8366 9.80408 19.5061 9.00439 18.9176 8.41394C18.329 7.82349 17.5303 7.49044 16.6966 7.48779ZM13.6966 17.4278C12.9549 17.4278 12.2299 17.2079 11.6132 16.7958C10.9966 16.3838 10.5159 15.7981 10.2321 15.1129C9.94826 14.4276 9.87398 13.6736 10.0187 12.9462C10.1634 12.2188 10.5205 11.5506 11.045 11.0262C11.5694 10.5017 12.2376 10.1445 12.965 9.99985C13.6925 9.85515 14.4465 9.92943 15.1317 10.2133C15.8169 10.4971 16.4026 10.9777 16.8146 11.5944C17.2267 12.2111 17.4466 12.9361 17.4466 13.6778C17.444 14.6681 17.0508 15.6174 16.3525 16.3195C15.6541 17.0216 14.7069 17.4199 13.7166 17.4278H13.6966ZM17.6966 10.5678C17.5182 10.5698 17.3432 10.5187 17.1939 10.421C17.0446 10.3233 16.9277 10.1835 16.858 10.0192C16.7884 9.85493 16.7691 9.67365 16.8027 9.49841C16.8363 9.32318 16.9212 9.1619 17.0467 9.03503C17.1722 8.90816 17.3325 8.82143 17.5073 8.78589C17.6822 8.75034 17.8637 8.76757 18.0287 8.83539C18.1937 8.9032 18.3349 9.01853 18.4342 9.16675C18.5336 9.31496 18.5866 9.48936 18.5866 9.66779C18.588 9.78635 18.5656 9.904 18.5208 10.0138C18.4761 10.1236 18.4098 10.2233 18.326 10.3072C18.2422 10.391 18.1424 10.4573 18.0326 10.502C17.9228 10.5468 17.8052 10.5691 17.6866 10.5678H17.6966Z"
                    fill={theme.texto}
                  />
                  <path
                    d="M19.8467 0.677734H7.4567C5.65311 0.680371 3.924 1.39733 2.64774 2.67172C1.37147 3.94611 0.65197 5.67417 0.646683 7.47775V19.8777C0.65197 21.6813 1.37147 23.4094 2.64774 24.6837C3.924 25.9581 5.65311 26.6751 7.4567 26.6777H19.8467C21.6485 26.6725 23.3751 25.9543 24.6492 24.6802C25.9233 23.4061 26.6414 21.6796 26.6467 19.8777V7.47775C26.6414 5.6759 25.9233 3.94934 24.6492 2.67523C23.3751 1.40113 21.6485 0.683011 19.8467 0.677734ZM21.2767 16.6777C21.274 17.8916 20.7907 19.055 19.9323 19.9134C19.074 20.7717 17.9106 21.2551 16.6967 21.2578H10.6067C9.39281 21.2551 8.22941 20.7717 7.37106 19.9134C6.51272 19.055 6.02933 17.8916 6.02669 16.6777V10.6377C6.02933 9.42385 6.51272 8.26044 7.37106 7.4021C8.22941 6.54376 9.39281 6.06038 10.6067 6.05774H16.6967C17.9106 6.06038 19.074 6.54376 19.9323 7.4021C20.7907 8.26044 21.274 9.42385 21.2767 10.6377V16.6777Z"
                    fill={theme.texto}
                  />
                </RedImage>
              </ContainerRed>
              {/* facebook */}
              <ContainerRed
                href="https://www.facebook.com/UshuaiaSolutions"
                target="_blank"
                aria-label="Facebook"
              >
                <RedImage
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  aria-label="Facebook"
                >
                  <path
                    d="M26.97 7.47775C26.9647 5.6759 26.2466 3.94934 24.9725 2.67523C23.6984 1.40113 21.9719 0.683011 20.17 0.677734H7.77C5.96815 0.683011 4.24162 1.40113 2.96751 2.67523C1.69341 3.94934 0.975278 5.6759 0.970001 7.47775V19.8777C0.975278 21.6796 1.69341 23.4061 2.96751 24.6802C4.24162 25.9543 5.96815 26.6725 7.77 26.6777H11.94V17.8577H8.63V14.1077H11.94V11.2377C11.94 7.97773 13.88 6.17773 16.85 6.17773C17.8251 6.19181 18.7978 6.27873 19.76 6.43774V9.63773H18.12C17.8433 9.60137 17.562 9.62708 17.2965 9.71295C17.031 9.79883 16.788 9.94273 16.585 10.1342C16.382 10.3257 16.2242 10.56 16.123 10.82C16.0218 11.0801 15.9798 11.3594 16 11.6377V14.0777H19.6L19.03 17.8277H16.03V26.6477H20.2C22.0019 26.6425 23.7284 25.9243 25.0025 24.6502C26.2766 23.3761 26.9947 21.6496 27 19.8477L26.97 7.47775Z"
                    fill={theme.texto}
                  />
                </RedImage>
              </ContainerRed>
            </ContainerRedesFooter>
          </ContainerText>

          <ColumnSecond>
            <LogoISO />
            <span className="txt-regular-mobile text-center-mobile">
              © 2024
              <CorteMobile />
              Ushuaia Solutions
            </span>
            <LogoUSH />
          </ColumnSecond>
        </ContainerMobile>

        <ContainerDesktop>
          <LogoUSH />
          <RowDesktop className="mt-40 mb-10">
            <p className="texto-regular text-left">
              We're committed to protecting your privacy. The information
              <CorteDesktop /> you provide will only be used to respond to your
              inquiry.
              <CorteDesktop />
              <CorteDesktop />
              Ushuaia Solutions is an ISO certified language services company
              <CorteDesktop /> with more than 20 years' experience. We have
              offices
              <CorteDesktop /> in Latin America and Europe.
            </p>
            <Column>
              <StyledLink to="/">
                <ButtonHover className="texto-regular-bold">Home</ButtonHover>
              </StyledLink>
              <StyledLink to="/about">
                <ButtonHover className="texto-regular-bold">About</ButtonHover>
              </StyledLink>
              <StyledLink to="/services">
                <ButtonHover className="texto-regular-bold">
                  Services
                </ButtonHover>
              </StyledLink>
            </Column>
            <Column>
              <StyledLink to="/expertise">
                <ButtonHover className="texto-regular-bold">
                  Expertise
                </ButtonHover>
              </StyledLink>
              <StyledLink to="/contact">
                <ButtonHover className="texto-regular-bold">
                  Get in touch
                </ButtonHover>
              </StyledLink>
              <StyledLink to="/ush-blog">
                <ButtonHover className="texto-regular-bold">
                  USH Blog
                </ButtonHover>
              </StyledLink>
            </Column>
          </RowDesktop>
          <LogoISO />
          <RowDesktop>
            <ContainerRedesFooter>
              {/* linkedin */}
              <ContainerRed
                href="https://www.linkedin.com/company/ushuaia-solutions/"
                target="_blank"
              >
                <RedImage
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="27"
                  viewBox="0 0 26 27"
                  fill="none"
                >
                  <path
                    d="M26 7.47775C25.9948 5.6759 25.2766 3.94934 24.0025 2.67523C22.7284 1.40113 21.0019 0.683011 19.2 0.677734H6.80003C4.99818 0.683011 3.27165 1.40113 1.99754 2.67523C0.723438 3.94934 0.00530683 5.6759 3.05176e-05 7.47775V19.8777C0.00530683 21.6796 0.723438 23.4061 1.99754 24.6802C3.27165 25.9543 4.99818 26.6725 6.80003 26.6777H19.2C21.0019 26.6725 22.7284 25.9543 24.0025 24.6802C25.2766 23.4061 25.9948 21.6796 26 19.8777V7.47775ZM10.07 19.3377H7.55003V11.2477H10.07V19.3377ZM8.81004 10.1377C8.52086 10.1377 8.23819 10.0518 7.99788 9.89096C7.75757 9.73009 7.57046 9.50149 7.46025 9.23413C7.35005 8.96677 7.32171 8.6727 7.37886 8.38922C7.43601 8.10574 7.57605 7.8456 7.78123 7.64182C7.98642 7.43803 8.24752 7.29977 8.53139 7.24457C8.81526 7.18937 9.10912 7.21972 9.37572 7.33176C9.64232 7.4438 9.86964 7.63249 10.0289 7.8739C10.1881 8.11531 10.272 8.39857 10.27 8.68774C10.2714 8.87984 10.2345 9.07028 10.1616 9.24802C10.0887 9.42575 9.98122 9.58724 9.84538 9.72308C9.70954 9.85892 9.54805 9.96641 9.37032 10.0393C9.19258 10.1122 9.00214 10.1491 8.81004 10.1477V10.1377ZM19.48 19.3377H16.97V15.3977C16.97 14.4677 16.97 13.2578 15.67 13.2578C14.37 13.2578 14.16 14.2577 14.16 15.3377V19.3377H11.64V11.2477H14.06V12.3377C14.3029 11.9268 14.6522 11.5891 15.0712 11.3602C15.4901 11.1314 15.963 11.02 16.44 11.0378C18.98 11.0378 19.44 12.7077 19.44 14.8877L19.48 19.3377Z"
                    fill={theme.texto}
                  />
                </RedImage>
              </ContainerRed>
              {/* youtube */}
              <ContainerRed
                href="https://www.youtube.com/channel/UCexqpO701H7nI72dplSkjaw"
                target="_blank"
              >
                <RedImage
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                >
                  <path
                    d="M15.4433 13.5578L12.0133 11.6878C11.8733 11.6078 11.3933 11.6878 11.3933 11.8778V15.5078C11.3933 15.6678 11.8733 15.7678 12.0133 15.6978L15.5933 13.9178C15.7333 13.8478 15.5833 13.6378 15.4433 13.5578Z"
                    fill={theme.texto}
                  />
                  <path
                    d="M19.5234 0.677734H7.12337C5.32152 0.683011 3.59497 1.40113 2.32086 2.67523C1.04676 3.94934 0.328641 5.6759 0.323364 7.47775V19.8777C0.328641 21.6796 1.04676 23.4061 2.32086 24.6802C3.59497 25.9543 5.32152 26.6725 7.12337 26.6777H19.5234C21.3252 26.6725 23.0518 25.9543 24.3259 24.6802C25.6 23.4061 26.3181 21.6796 26.3234 19.8777V7.47775C26.3181 5.6759 25.6 3.94934 24.3259 2.67523C23.0518 1.40113 21.3252 0.683011 19.5234 0.677734ZM20.2834 15.4977C20.2847 15.9083 20.2049 16.3151 20.0487 16.6948C19.8925 17.0745 19.6629 17.4196 19.3731 17.7104C19.0832 18.0012 18.7388 18.2319 18.3596 18.3893C17.9805 18.5467 17.5739 18.6277 17.1634 18.6277H9.16336C8.75279 18.6277 8.34626 18.5467 7.96707 18.3893C7.58788 18.2319 7.24349 18.0012 6.95364 17.7104C6.66379 17.4196 6.43419 17.0745 6.27798 16.6948C6.12178 16.3151 6.04205 15.9083 6.04337 15.4977V11.7878C6.04337 10.9603 6.37207 10.1667 6.95718 9.58157C7.5423 8.99646 8.33589 8.66772 9.16336 8.66772H17.1634C17.9908 8.66772 18.7844 8.99646 19.3695 9.58157C19.9547 10.1667 20.2834 10.9603 20.2834 11.7878V15.4977Z"
                    fill={theme.texto}
                  />
                </RedImage>
              </ContainerRed>
              {/* instagram */}
              <ContainerRed
                href="https://www.instagram.com/ushuaiasolutions/"
                target="_blank"
              >
                <RedImage
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                >
                  <path
                    d="M13.7167 11.2676C13.2396 11.2656 12.7727 11.4053 12.3751 11.6689C11.9774 11.9325 11.667 12.3082 11.483 12.7484C11.2991 13.1886 11.2499 13.6735 11.3418 14.1416C11.4337 14.6098 11.6624 15.0401 11.999 15.3782C12.3357 15.7162 12.7651 15.9467 13.2329 16.0405C13.7006 16.1344 14.1857 16.0872 14.6267 15.9051C15.0676 15.723 15.4446 15.4141 15.7098 15.0176C15.9751 14.621 16.1167 14.1547 16.1167 13.6776C16.1167 13.0402 15.8642 12.4287 15.4144 11.977C14.9646 11.5253 14.3541 11.2702 13.7167 11.2676Z"
                    fill={theme.texto}
                  />
                  <path
                    d="M16.6966 7.48779H10.6066C9.77293 7.49044 8.97429 7.82349 8.38571 8.41394C7.79713 9.00439 7.46662 9.80408 7.46663 10.6378V16.7178C7.46662 17.5515 7.79713 18.3512 8.38571 18.9417C8.97429 19.5321 9.77293 19.8652 10.6066 19.8678H16.6966C17.5303 19.8652 18.329 19.5321 18.9176 18.9417C19.5061 18.3512 19.8366 17.5515 19.8366 16.7178V10.6378C19.8366 9.80408 19.5061 9.00439 18.9176 8.41394C18.329 7.82349 17.5303 7.49044 16.6966 7.48779ZM13.6966 17.4278C12.9549 17.4278 12.2299 17.2079 11.6132 16.7958C10.9966 16.3838 10.5159 15.7981 10.2321 15.1129C9.94826 14.4276 9.87398 13.6736 10.0187 12.9462C10.1634 12.2188 10.5205 11.5506 11.045 11.0262C11.5694 10.5017 12.2376 10.1445 12.965 9.99985C13.6925 9.85515 14.4465 9.92943 15.1317 10.2133C15.8169 10.4971 16.4026 10.9777 16.8146 11.5944C17.2267 12.2111 17.4466 12.9361 17.4466 13.6778C17.444 14.6681 17.0508 15.6174 16.3525 16.3195C15.6541 17.0216 14.7069 17.4199 13.7166 17.4278H13.6966ZM17.6966 10.5678C17.5182 10.5698 17.3432 10.5187 17.1939 10.421C17.0446 10.3233 16.9277 10.1835 16.858 10.0192C16.7884 9.85493 16.7691 9.67365 16.8027 9.49841C16.8363 9.32318 16.9212 9.1619 17.0467 9.03503C17.1722 8.90816 17.3325 8.82143 17.5073 8.78589C17.6822 8.75034 17.8637 8.76757 18.0287 8.83539C18.1937 8.9032 18.3349 9.01853 18.4342 9.16675C18.5336 9.31496 18.5866 9.48936 18.5866 9.66779C18.588 9.78635 18.5656 9.904 18.5208 10.0138C18.4761 10.1236 18.4098 10.2233 18.326 10.3072C18.2422 10.391 18.1424 10.4573 18.0326 10.502C17.9228 10.5468 17.8052 10.5691 17.6866 10.5678H17.6966Z"
                    fill={theme.texto}
                  />
                  <path
                    d="M19.8467 0.677734H7.4567C5.65311 0.680371 3.924 1.39733 2.64774 2.67172C1.37147 3.94611 0.65197 5.67417 0.646683 7.47775V19.8777C0.65197 21.6813 1.37147 23.4094 2.64774 24.6837C3.924 25.9581 5.65311 26.6751 7.4567 26.6777H19.8467C21.6485 26.6725 23.3751 25.9543 24.6492 24.6802C25.9233 23.4061 26.6414 21.6796 26.6467 19.8777V7.47775C26.6414 5.6759 25.9233 3.94934 24.6492 2.67523C23.3751 1.40113 21.6485 0.683011 19.8467 0.677734ZM21.2767 16.6777C21.274 17.8916 20.7907 19.055 19.9323 19.9134C19.074 20.7717 17.9106 21.2551 16.6967 21.2578H10.6067C9.39281 21.2551 8.22941 20.7717 7.37106 19.9134C6.51272 19.055 6.02933 17.8916 6.02669 16.6777V10.6377C6.02933 9.42385 6.51272 8.26044 7.37106 7.4021C8.22941 6.54376 9.39281 6.06038 10.6067 6.05774H16.6967C17.9106 6.06038 19.074 6.54376 19.9323 7.4021C20.7907 8.26044 21.274 9.42385 21.2767 10.6377V16.6777Z"
                    fill={theme.texto}
                  />
                </RedImage>
              </ContainerRed>
              {/* facebook */}
              <ContainerRed
                href="https://www.facebook.com/UshuaiaSolutions"
                target="_blank"
              >
                <RedImage
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                >
                  <path
                    d="M26.97 7.47775C26.9647 5.6759 26.2466 3.94934 24.9725 2.67523C23.6984 1.40113 21.9719 0.683011 20.17 0.677734H7.77C5.96815 0.683011 4.24162 1.40113 2.96751 2.67523C1.69341 3.94934 0.975278 5.6759 0.970001 7.47775V19.8777C0.975278 21.6796 1.69341 23.4061 2.96751 24.6802C4.24162 25.9543 5.96815 26.6725 7.77 26.6777H11.94V17.8577H8.63V14.1077H11.94V11.2377C11.94 7.97773 13.88 6.17773 16.85 6.17773C17.8251 6.19181 18.7978 6.27873 19.76 6.43774V9.63773H18.12C17.8433 9.60137 17.562 9.62708 17.2965 9.71295C17.031 9.79883 16.788 9.94273 16.585 10.1342C16.382 10.3257 16.2242 10.56 16.123 10.82C16.0218 11.0801 15.9798 11.3594 16 11.6377V14.0777H19.6L19.03 17.8277H16.03V26.6477H20.2C22.0019 26.6425 23.7284 25.9243 25.0025 24.6502C26.2766 23.3761 26.9947 21.6496 27 19.8477L26.97 7.47775Z"
                    fill={theme.texto}
                  />
                </RedImage>
              </ContainerRed>
            </ContainerRedesFooter>
            <span className="txt-regular">© 2024 Ushuaia Solutions</span>
          </RowDesktop>
        </ContainerDesktop>
      </Container>
    </Content>
  );
}

export default Footer;
