import React from "react";
import styled, { useTheme } from "styled-components";
import { breakpoints } from "../../components/base/breakpoints";

function Business() {
  const theme = useTheme();

  return (
    <SVG
      width="51"
      height="46"
      viewBox="0 0 51 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.4945 13.2967C50.4945 9.50549 47.4176 6.42857 43.6264 6.42857H35.1099C34.6154 2.8022 31.5385 0 27.8022 0H22.6923C18.956 0 15.8242 2.8022 15.3297 6.42857H6.86813C3.07692 6.42857 0 9.50549 0 13.2967V29.1209C0 30.7692 0.604396 32.3077 1.59341 33.5165V38.1868C1.59341 41.978 4.67033 45.0549 8.46154 45.0549H42.0879C45.8791 45.0549 48.956 41.978 48.956 38.1868V33.5165C49.945 32.3077 50.5494 30.8242 50.5494 29.1209V13.2967H50.4945ZM22.6923 3.84615H27.8022C29.4505 3.84615 30.8242 4.94505 31.2637 6.42857H19.2308C19.6703 4.94505 21.044 3.84615 22.6923 3.84615ZM3.84615 13.2967C3.84615 11.6484 5.21978 10.2747 6.86813 10.2747H15.2747H35.2198H43.6264C45.2747 10.2747 46.6483 11.6484 46.6483 13.2967V29.1209C46.6483 30.7692 45.2747 32.1429 43.6264 32.1429H32.0879V29.2308C32.0879 26.8681 30.1648 24.9451 27.8022 24.9451H22.6923C20.3297 24.9451 18.4066 26.8681 18.4066 29.2308V32.1429H6.86813C5.21978 32.1429 3.84615 30.7692 3.84615 29.1209V13.2967ZM28.2418 29.1758V32.6923C28.2418 34.3407 26.9231 35.6593 25.2747 35.6593C23.956 35.6593 22.7473 34.7802 22.4176 33.4615C22.3626 33.1868 22.3077 32.9121 22.3077 32.6374V29.1209C22.3077 28.9011 22.4725 28.6813 22.7473 28.6813H27.8571C28.022 28.7363 28.2418 28.956 28.2418 29.1758ZM42.033 41.1538H8.46154C6.81319 41.1538 5.43956 39.7802 5.43956 38.1319V35.7692C5.87912 35.8791 6.37363 35.9341 6.86813 35.9341H19.2308C20.3846 38.1319 22.6923 39.5055 25.2198 39.5055C27.8022 39.5055 30.0549 38.022 31.2088 35.9341H43.5714C44.0659 35.9341 44.5604 35.8791 45 35.7692V38.1319C45.0549 39.8352 43.6813 41.1538 42.033 41.1538Z"
        fill={theme.texto}
      />
    </SVG>
  );
}

const SVG = styled.svg`
  width: 23px;
  height: 46px;

  & path {
    transition: 0.2s;
  }

  ${breakpoints.min_desktop} {
    width: 51px;
    height: 46px;
  }
`;

export default Business;
