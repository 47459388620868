import React from "react";
import styled, { useTheme } from "styled-components";
import { breakpoints } from "../components/base/breakpoints";

function LogoUSH() {
  const theme = useTheme();

  return (
    <SVG
      width="155"
      height="47"
      viewBox="0 0 155 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Logo USH">
        <g id="Language Services">
          <path
            id="Vector"
            d="M33.4604 46.3825V43.1501C33.4604 43.0102 33.5375 42.9325 33.6764 42.9325H34.0004C34.1393 42.9325 34.2165 43.0102 34.2165 43.1501V45.9784H35.6052C35.7441 45.9784 35.8367 46.0561 35.8367 46.196V46.398C35.8367 46.5379 35.7596 46.6156 35.6052 46.6156H33.6764C33.5375 46.6001 33.4604 46.5224 33.4604 46.3825Z"
            fill={theme.texto}
          />
          <path
            id="Vector_2"
            d="M41.4998 46.6H41.0678C40.9289 46.6 40.898 46.5223 40.8363 46.3824L40.6357 45.9007H38.9692L38.7686 46.3824C38.7069 46.5223 38.6914 46.6 38.5371 46.6H38.1205C37.9816 46.6 37.9045 46.5223 37.9816 46.3824L39.5247 43.0879C39.5864 42.9635 39.6636 42.9014 39.787 42.9014H39.8333C39.9568 42.9014 40.0493 42.9791 40.0956 43.0879L41.6387 46.3824C41.7159 46.5223 41.6542 46.6 41.4998 46.6ZM40.4043 45.3568L39.8179 43.927L39.2161 45.3568H40.4043Z"
            fill={theme.texto}
          />
          <path
            id="Vector_3"
            d="M47.3636 43.15V46.398C47.3636 46.5689 47.2401 46.6311 47.1167 46.6311H47.0704C46.9469 46.6311 46.8389 46.5534 46.7309 46.4291L44.9255 44.2534V46.3824C44.9255 46.5223 44.8483 46.6156 44.7095 46.6156H44.3854C44.2465 46.6156 44.1694 46.5379 44.1694 46.3824V43.1345C44.1694 42.9635 44.2928 42.9014 44.4163 42.9014H44.4626C44.586 42.9014 44.694 42.9791 44.802 43.1034L46.6074 45.2791V43.15C46.6074 43.0101 46.6846 42.9324 46.8235 42.9324H47.1475C47.2864 42.9169 47.3636 42.9946 47.3636 43.15Z"
            fill={theme.texto}
          />
          <path
            id="Vector_4"
            d="M49.9868 44.7662C49.9868 43.6628 50.8355 42.8547 51.9002 42.8547C52.4558 42.8547 52.9495 43.0723 53.289 43.4297C53.3816 43.523 53.3816 43.6473 53.2736 43.7405L53.073 43.927C52.965 44.0203 52.8724 44.0047 52.7644 43.9115C52.5483 43.6939 52.2397 43.5541 51.9157 43.5541C51.2676 43.5541 50.7584 44.0514 50.7584 44.7662C50.7584 45.4811 51.2521 45.9784 51.9157 45.9784C52.2243 45.9784 52.502 45.8696 52.7026 45.6676C52.8724 45.5122 52.965 45.3101 52.9958 45.077H52.1317C51.9928 45.077 51.9157 44.9993 51.9157 44.8595V44.7196C51.9157 44.5797 51.9928 44.502 52.1317 44.502H53.5668C53.7057 44.502 53.7828 44.5797 53.7828 44.7196V44.7973C53.7828 45.3101 53.5822 45.7764 53.2427 46.1182C52.9032 46.4601 52.4403 46.6622 51.8848 46.6622C50.8201 46.6777 49.9868 45.8541 49.9868 44.7662Z"
            fill={theme.texto}
          />
          <path
            id="Vector_5"
            d="M56.3289 45.015V43.1346C56.3289 42.9947 56.406 42.917 56.5449 42.917H56.869C57.0078 42.917 57.085 42.9947 57.085 43.1346V45.0305C57.085 45.59 57.4399 45.9629 57.98 45.9629C58.5201 45.9629 58.875 45.59 58.875 45.0305V43.1346C58.875 42.9947 58.9521 42.917 59.091 42.917H59.3996C59.5385 42.917 59.6157 42.9947 59.6157 43.1346V45.015C59.6157 46.0096 58.9521 46.6623 57.98 46.6623C56.9924 46.6778 56.3289 46.0096 56.3289 45.015Z"
            fill={theme.texto}
          />
          <path
            id="Vector_6"
            d="M65.464 46.6H65.0319C64.893 46.6 64.8622 46.5223 64.8004 46.3824L64.5998 45.9007H62.9333L62.7327 46.3824C62.671 46.5223 62.6555 46.6 62.5012 46.6H62.0846C61.9457 46.6 61.8686 46.5223 61.9457 46.3824L63.4888 43.0879C63.5505 42.9635 63.6277 42.9014 63.7511 42.9014H63.7974C63.9209 42.9014 64.0135 42.9791 64.0598 43.0879L65.6028 46.3824C65.68 46.5223 65.6028 46.6 65.464 46.6ZM64.3684 45.3568L63.782 43.927L63.1802 45.3568H64.3684Z"
            fill={theme.texto}
          />
          <path
            id="Vector_7"
            d="M67.7632 44.7662C67.7632 43.6628 68.6118 42.8547 69.6766 42.8547C70.2321 42.8547 70.7259 43.0723 71.0654 43.4297C71.1579 43.523 71.1579 43.6473 71.0499 43.7405L70.8493 43.927C70.7413 44.0203 70.6487 44.0047 70.5407 43.9115C70.3247 43.6939 70.0161 43.5541 69.692 43.5541C69.0439 43.5541 68.5347 44.0514 68.5347 44.7662C68.5347 45.4811 69.0285 45.9784 69.692 45.9784C70.0006 45.9784 70.2784 45.8696 70.479 45.6676C70.6487 45.5122 70.7413 45.3101 70.7722 45.077H69.908C69.7692 45.077 69.692 44.9993 69.692 44.8595V44.7196C69.692 44.5797 69.7692 44.502 69.908 44.502H71.3431C71.482 44.502 71.5591 44.5797 71.5591 44.7196V44.7973C71.5591 45.3101 71.3585 45.7764 71.0191 46.1182C70.6796 46.4601 70.2167 46.6622 69.6612 46.6622C68.581 46.6777 67.7632 45.8541 67.7632 44.7662Z"
            fill={theme.texto}
          />
          <path
            id="Vector_8"
            d="M74.1515 46.3825V43.1501C74.1515 43.0102 74.2287 42.9325 74.383 42.9325H76.5433C76.6822 42.9325 76.7748 43.0102 76.7748 43.1501V43.3521C76.7748 43.492 76.6976 43.5697 76.5433 43.5697H74.9231V44.471H76.1421C76.281 44.471 76.3736 44.5487 76.3736 44.6886V44.8595C76.3736 44.9994 76.2964 45.0771 76.1421 45.0771H74.9231V45.994H76.5588C76.6976 45.994 76.7748 46.0717 76.7748 46.2115V46.4136C76.7748 46.5534 76.6976 46.6311 76.5588 46.6311H74.383C74.2287 46.6001 74.1515 46.5224 74.1515 46.3825Z"
            fill={theme.texto}
          />
          <path
            id="Vector_9"
            d="M82.4225 46.1027C82.3145 45.994 82.2836 45.8852 82.407 45.7764L82.5305 45.621C82.6385 45.5122 82.7465 45.4811 82.8545 45.5899C83.086 45.8541 83.4872 46.0095 83.7958 46.0095C84.197 46.0095 84.4593 45.8386 84.4593 45.5744C84.4593 45.2014 84.0427 45.1548 83.5489 44.9838C83.086 44.8284 82.4842 44.5642 82.4842 43.896C82.4842 43.2744 83.0243 42.8392 83.8113 42.8392C84.3205 42.8392 84.7217 43.0102 85.0149 43.2744C85.1229 43.3676 85.1692 43.4764 85.0612 43.6007L84.9377 43.7561C84.8451 43.8649 84.7371 43.8805 84.6137 43.7872C84.4131 43.6163 84.1662 43.4764 83.8421 43.4764C83.5181 43.4764 83.2712 43.6318 83.2712 43.8649C83.2712 44.1446 83.5644 44.2534 83.8884 44.3622C84.5056 44.5642 85.2463 44.7507 85.2463 45.5744C85.2463 46.2582 84.6291 46.6622 83.8575 46.6622C83.2712 46.6777 82.7465 46.4602 82.4225 46.1027Z"
            fill={theme.texto}
          />
          <path
            id="Vector_10"
            d="M87.8541 46.3825V43.1501C87.8541 43.0102 87.9313 42.9325 88.0856 42.9325H90.2459C90.3848 42.9325 90.4774 43.0102 90.4774 43.1501V43.3521C90.4774 43.492 90.4002 43.5697 90.2459 43.5697H88.6103V44.471H89.8293C89.9682 44.471 90.0608 44.5487 90.0608 44.6886V44.8595C90.0608 44.9994 89.9836 45.0771 89.8293 45.0771H88.6103V45.994H90.2459C90.3848 45.994 90.462 46.0717 90.462 46.2115V46.4136C90.462 46.5534 90.3848 46.6311 90.2459 46.6311H88.0702C87.9313 46.6001 87.8541 46.5224 87.8541 46.3825Z"
            fill={theme.texto}
          />
          <path
            id="Vector_11"
            d="M95.9399 46.3825C96.0171 46.5224 95.9399 46.6156 95.801 46.6156H95.3998C95.261 46.6156 95.2147 46.5534 95.1375 46.4136L94.4586 45.1859H93.8413V46.3825C93.8413 46.5224 93.7642 46.6156 93.6253 46.6156H93.3012C93.1624 46.6156 93.0852 46.5379 93.0852 46.3825V43.1501C93.0852 43.0102 93.1624 42.9325 93.3167 42.9325H94.6129C95.5233 42.9325 96.0016 43.3676 96.0016 44.0825C96.0016 44.5953 95.7085 44.9528 95.2301 45.1082L95.9399 46.3825ZM93.8413 44.6264H94.6437C94.9986 44.6264 95.2147 44.4244 95.2147 44.098C95.2147 43.7717 94.9832 43.5541 94.6437 43.5541H93.8413V44.6264Z"
            fill={theme.texto}
          />
          <path
            id="Vector_12"
            d="M101.804 43.1346L100.291 46.4136C100.23 46.5535 100.153 46.6156 100.029 46.6156H99.9828C99.8594 46.6156 99.7976 46.5535 99.7205 46.4136L98.2083 43.1346C98.1465 42.9947 98.2391 42.917 98.378 42.917H98.7329C98.8718 42.917 98.9644 42.9792 99.0261 43.1346L99.9982 45.4812L101.001 43.1346C101.063 42.9947 101.156 42.917 101.294 42.917H101.618C101.773 42.917 101.865 42.9947 101.804 43.1346Z"
            fill={theme.texto}
          />
          <path
            id="Vector_13"
            d="M104.319 46.3825V43.1501C104.319 43.0102 104.396 42.9325 104.535 42.9325H104.859C104.998 42.9325 105.075 43.0102 105.075 43.1501V46.3825C105.075 46.5224 104.998 46.6156 104.859 46.6156H104.535C104.396 46.6001 104.319 46.5224 104.319 46.3825Z"
            fill={theme.texto}
          />
          <path
            id="Vector_14"
            d="M107.698 44.7662C107.698 43.6784 108.562 42.8547 109.658 42.8547C110.213 42.8547 110.723 43.0723 111.062 43.4453C111.17 43.5541 111.155 43.6628 111.047 43.7561L110.846 43.927C110.738 44.0047 110.646 44.0047 110.538 43.9115C110.306 43.6939 110.013 43.5541 109.673 43.5541C109.01 43.5541 108.485 44.0669 108.485 44.7662C108.485 45.4811 109.01 45.9784 109.673 45.9784C110.013 45.9784 110.321 45.8385 110.538 45.621C110.646 45.5277 110.738 45.5122 110.846 45.6054L111.047 45.7764C111.155 45.8696 111.155 45.9784 111.062 46.0872C110.723 46.4601 110.229 46.6777 109.658 46.6777C108.547 46.6777 107.698 45.8541 107.698 44.7662Z"
            fill={theme.texto}
          />
          <path
            id="Vector_15"
            d="M113.685 46.3825V43.1501C113.685 43.0102 113.763 42.9325 113.917 42.9325H116.077C116.216 42.9325 116.309 43.0102 116.309 43.1501V43.3521C116.309 43.492 116.232 43.5697 116.077 43.5697H114.457V44.471H115.676C115.815 44.471 115.907 44.5487 115.907 44.6886V44.8595C115.907 44.9994 115.83 45.0771 115.676 45.0771H114.457V45.994H116.077C116.216 45.994 116.293 46.0717 116.293 46.2115V46.4136C116.293 46.5534 116.216 46.6311 116.077 46.6311H113.901C113.763 46.6001 113.685 46.5224 113.685 46.3825Z"
            fill={theme.texto}
          />
          <path
            id="Vector_16"
            d="M118.716 46.1027C118.608 45.994 118.577 45.8852 118.7 45.7764L118.824 45.621C118.932 45.5122 119.04 45.4811 119.148 45.5899C119.379 45.8541 119.781 46.0095 120.089 46.0095C120.49 46.0095 120.753 45.8386 120.753 45.5744C120.753 45.2014 120.336 45.1548 119.842 44.9838C119.379 44.8284 118.778 44.5642 118.778 43.896C118.778 43.2744 119.318 42.8392 120.105 42.8392C120.614 42.8392 121.015 43.0102 121.308 43.2744C121.416 43.3676 121.463 43.4764 121.355 43.6007L121.231 43.7561C121.139 43.8649 121.031 43.8805 120.907 43.7872C120.706 43.6163 120.46 43.4764 120.136 43.4764C119.811 43.4764 119.565 43.6318 119.565 43.8649C119.565 44.1446 119.858 44.2534 120.182 44.3622C120.799 44.5642 121.54 44.7507 121.54 45.5744C121.54 46.2582 120.922 46.6622 120.151 46.6622C119.58 46.6777 119.04 46.4602 118.716 46.1027Z"
            fill={theme.texto}
          />
        </g>
        <g id="Ushuaia">
          <path
            id="Vector_17"
            d="M21.9489 15.55V32.598C21.9489 33.95 21.1928 34.696 19.8195 34.696H17.474C16.1315 34.696 15.3908 33.9345 15.3908 32.598V31.8831C14.1718 33.8257 12.2121 35.2554 9.24933 35.2554C4.01827 35.2554 0.51546 31.8521 0.51546 26.7081V15.55C0.500029 14.198 1.25614 13.4365 2.5832 13.4365H6.64151C7.984 13.4365 8.77097 14.198 8.77097 15.5345V25.4338C8.77097 27.0345 9.65053 28.2622 11.1936 28.2622C12.783 28.2622 13.6626 27.0811 13.6626 25.4338V15.55C13.6626 14.198 14.4187 13.4521 15.7457 13.4521H19.804C21.1928 13.4365 21.9489 14.198 21.9489 15.55Z"
            fill={theme.texto}
          />
          <path
            id="Vector_18"
            d="M25.1123 32.3494C24.0321 31.3859 23.8161 30.5778 24.8654 29.319L26.0844 27.796C27.1337 26.4906 28.1676 26.9102 29.1397 27.5474C30.6056 28.4798 32.3956 29.2258 34.3245 29.2258C35.204 29.2258 35.667 28.9305 35.667 28.4332C35.667 27.765 34.6177 27.4231 33.661 27.1278C29.6952 25.9933 25.2203 24.6413 25.2203 19.8859C25.2203 15.6744 28.7385 13.0636 34.2165 13.0636C37.3952 13.0636 40.8209 14.1514 42.2097 15.3014C43.2898 16.1872 43.4596 16.9798 42.3794 18.3785L41.3764 19.7305C40.5894 20.7872 39.4475 20.694 38.4445 20.1035C37.3181 19.4352 35.9293 18.8758 34.3399 18.8758C33.4141 18.8758 32.9203 19.171 32.9203 19.5906C32.9203 20.4764 34.9726 20.7251 37.6421 21.6886C41.0678 22.9474 43.367 24.8122 43.367 28.34C43.367 32.769 39.6481 35.2866 34.2165 35.2866C30.2507 35.2866 26.7479 33.7791 25.1123 32.3494Z"
            fill={theme.texto}
          />
          <path
            id="Vector_19"
            d="M67.6397 22.3568V32.5825C67.6397 33.9345 66.8836 34.6804 65.5103 34.6804H61.4519C60.1095 34.6804 59.3688 33.9189 59.3688 32.5825V22.9318C59.3688 21.2845 58.3658 20.0723 56.6992 20.0723C55.0327 20.0723 54.0297 21.3 54.0297 22.9318V32.5825C54.0297 33.9345 53.2736 34.6804 51.9465 34.6804H47.9808C46.6383 34.6804 45.8976 33.9189 45.8976 32.5825V7.54665C45.8976 6.16354 46.6537 5.65071 47.9808 5.40206L51.9465 4.68719C53.289 4.51625 54.0297 5.44868 54.0297 6.78517V14.6953C55.3259 13.7318 56.9153 13.0946 58.9213 13.0946C63.9517 13.0946 67.6397 16.6845 67.6397 22.3568Z"
            fill={theme.texto}
          />
          <path
            id="Vector_20"
            d="M91.5267 15.55V32.598C91.5267 33.95 90.7706 34.6959 89.3972 34.6959H87.0517C85.7093 34.6959 84.9686 33.9344 84.9686 32.598V31.8831C83.7495 33.8257 81.7898 35.2554 78.8271 35.2554C73.596 35.2554 70.0932 31.852 70.0932 26.7081V15.55C70.0932 14.198 70.8493 13.452 72.1764 13.452H76.2347C77.5772 13.452 78.3642 14.2135 78.3642 15.55V25.4493C78.3642 27.05 79.2437 28.2777 80.7868 28.2777C82.3762 28.2777 83.2557 27.0966 83.2557 25.4493V15.55C83.2557 14.198 84.0119 13.452 85.3389 13.452H89.3972C90.7706 13.4365 91.5267 14.198 91.5267 15.55Z"
            fill={theme.texto}
          />
          <path
            id="Vector_21"
            d="M117.157 15.2858V32.598C117.157 33.95 116.401 34.696 115.074 34.696H112.775C111.402 34.696 110.646 33.9345 110.646 32.598C109.056 34.1987 106.88 35.2554 104.211 35.2554C98.2391 35.2554 93.795 30.4534 93.795 24.175C93.795 17.8966 98.1928 13.0946 104.211 13.0946C106.726 13.0946 108.886 14.027 110.646 15.4568V15.2858C110.646 13.9338 111.402 13.1878 112.775 13.1878H115.074C116.401 13.1878 117.157 13.9493 117.157 15.2858ZM109.288 24.175C109.288 21.8595 107.698 20.1345 105.569 20.1345C103.439 20.1345 101.896 21.8595 101.896 24.175C101.896 26.4905 103.439 28.2155 105.569 28.2155C107.621 28.2155 109.288 26.4439 109.288 24.175Z"
            fill={theme.texto}
          />
          <path
            id="Vector_22"
            d="M119.919 5.52638C119.919 2.83787 122.049 0.677734 124.688 0.677734C127.357 0.677734 129.502 2.82233 129.502 5.52638C129.502 8.18382 127.373 10.3284 124.688 10.3284C122.049 10.3284 119.919 8.16828 119.919 5.52638ZM120.583 32.598V15.55C120.583 14.198 121.339 13.4521 122.666 13.4521H126.724C128.067 13.4521 128.808 14.2135 128.808 15.55V32.598C128.808 33.95 128.052 34.696 126.724 34.696H122.666C121.339 34.696 120.583 33.95 120.583 32.598Z"
            fill={theme.texto}
          />
          <path
            id="Vector_23"
            d="M154.5 15.2858V32.598C154.5 33.95 153.744 34.696 152.417 34.696H150.118C148.744 34.696 147.988 33.9345 147.988 32.598C146.399 34.1987 144.223 35.2554 141.554 35.2554C135.582 35.2554 131.138 30.4534 131.138 24.175C131.138 17.8966 135.535 13.0946 141.554 13.0946C144.069 13.0946 146.229 14.027 147.988 15.4568V15.2858C147.988 13.9338 148.744 13.1878 150.118 13.1878H152.417C153.744 13.1878 154.5 13.9493 154.5 15.2858ZM146.63 24.175C146.63 21.8595 145.041 20.1345 142.911 20.1345C140.782 20.1345 139.239 21.8595 139.239 24.175C139.239 26.4905 140.782 28.2155 142.911 28.2155C144.964 28.2155 146.63 26.4439 146.63 24.175Z"
            fill={theme.texto}
          />
        </g>
      </g>
    </SVG>
  );
}

const SVG = styled.svg`
  width: 154px;
  height: 46px;
  margin: 24px 0 0;

  & path {
    transition: 0.2s;
  }

  ${breakpoints.min_desktop} {
    width: 251px;
    height: 75px;
    margin: 80px 0 0;
  }
`;

export default LogoUSH;
