import React from "react";
import styled, { useTheme } from "styled-components";
import { breakpoints } from "../../components/base/breakpoints";

function Arts() {
  const theme = useTheme();

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="50"
      viewBox="0 0 45 50"
      fill="none"
    >
      <path
        d="M39.2373 39.655H14.3679L3.29161 25.0782C2.66465 24.2945 1.51522 24.1378 0.731522 24.7125C-0.0521767 25.3394 -0.208917 26.4889 0.365796 27.2726L12.0168 42.5808C12.3825 43.051 12.905 43.3123 13.4797 43.3123H16.092L14.6291 47.5965C14.3156 48.5369 14.7859 49.5819 15.7785 49.8953C15.9875 49.9476 16.1965 49.9998 16.3533 49.9998C17.137 49.9998 17.8162 49.5296 18.0774 48.7459L19.9583 43.26H32.4452L34.3783 48.7459C34.6396 49.4774 35.371 49.9476 36.1025 49.9476C36.3115 49.9476 36.5205 49.8953 36.7294 49.8431C37.6699 49.5296 38.1923 48.4847 37.8266 47.492L36.3115 43.2078H39.2373C40.23 43.2078 41.0659 42.3718 41.0659 41.3791C41.0659 40.3865 40.2822 39.655 39.2373 39.655Z"
        fill={theme.texto}
      />
      <path
        d="M26.9074 2.45559V1.82863C26.9074 0.835946 26.0715 0 25.0788 0C24.0861 0 23.2502 0.835946 23.2502 1.82863V2.45559C6.74025 3.44828 5.27734 17.8683 5.22509 17.9728C5.17285 18.7565 5.59082 19.488 6.27003 19.8015C6.94923 20.1149 7.78518 19.9582 8.35989 19.4357C9.61381 18.1818 10.8677 17.5549 12.0172 17.6071C13.689 17.7116 14.8907 19.2268 14.8907 19.2268C15.2042 19.6447 15.6744 19.906 16.2491 19.9582C16.7716 20.0105 17.2941 19.8015 17.6598 19.4357C18.757 18.3386 19.8541 17.7638 20.8468 17.8161C22.0485 17.8683 22.9889 18.7565 23.2502 19.1223V36.4159C23.2502 37.4086 24.0861 38.2445 25.0788 38.2445C26.0715 38.2445 26.9074 37.4086 26.9074 36.4159V18.7565C27.6389 18.1818 28.3703 17.9206 29.1018 17.9728C30.5647 18.0251 31.7141 19.3312 31.7141 19.3312C32.0276 19.7492 32.4978 19.9582 33.0203 20.0105C33.5428 20.0627 34.013 19.8537 34.3787 19.488C35.4759 18.3908 36.5731 17.8683 37.7225 17.8683C39.6556 17.8683 41.223 19.4357 41.223 19.4357C41.5887 19.8015 42.0589 20.0105 42.5292 20.0105C42.7904 20.0105 42.9994 19.9582 43.2606 19.8537C43.9398 19.5402 44.41 18.861 44.3578 18.0773C44.3578 17.9206 43.4174 3.44828 26.9074 2.45559ZM37.827 14.1588C36.1551 14.1588 34.5877 14.6813 33.1248 15.7262C32.2366 15.047 30.8782 14.3678 29.2585 14.3156C29.154 14.3156 29.0495 14.3156 28.9451 14.3156C27.5344 14.3156 26.176 14.7858 24.8698 15.7262C23.9816 15.047 22.6755 14.3156 21.0558 14.2111C19.4362 14.1066 17.8688 14.5768 16.4059 15.674C15.4654 14.9425 14.0548 14.1066 12.2784 14.0021C11.5469 13.9498 10.8155 14.0021 10.1363 14.2111C11.8604 10.6583 15.8312 6.06061 25.0788 6.06061C34.5354 6.06061 38.2972 10.8673 39.7601 14.4723C39.1854 14.3156 38.5062 14.1588 37.827 14.1588Z"
        fill={theme.texto}
      />
    </SVG>
  );
}
const SVG = styled.svg`
  width: 22.17px;
  height: 25px;

  & path {
    transition: 0.2s;
  }

  ${breakpoints.min_desktop} {
    width: 44.341px;
    height: 50px;
  }
`;

export default Arts;
