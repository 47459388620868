import React from "react";
import styled, { useTheme } from "styled-components";
import { breakpoints } from "../../components/base/breakpoints";

function Reliability() {
  const theme = useTheme();

  return (
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
    >
      <g style={{ mixBlendMode: "multiply" }}>
        <path
          d="M30 44.8854C38.1169 44.8854 44.697 38.2887 44.697 30.1512C44.697 22.0137 38.1169 15.417 30 15.417C21.8831 15.417 15.303 22.0137 15.303 30.1512C15.303 38.2887 21.8831 44.8854 30 44.8854Z"
          fill={theme.texto}
        />
      </g>
      <path
        opacity="0.7"
        d="M14.697 60.2277C22.8139 60.2277 29.394 53.631 29.394 45.4935C29.394 37.356 22.8139 30.7593 14.697 30.7593C6.58007 30.7593 0 37.356 0 45.4935C0 53.631 6.58007 60.2277 14.697 60.2277Z"
        fill={theme.texto}
      />
      <path
        opacity="0.7"
        d="M45.3032 60.2277C53.4201 60.2277 60.0002 53.631 60.0002 45.4935C60.0002 37.356 53.4201 30.7593 45.3032 30.7593C37.1863 30.7593 30.6062 37.356 30.6062 45.4935C30.6062 53.631 37.1863 60.2277 45.3032 60.2277Z"
        fill={theme.texto}
      />
      <path
        opacity="0.7"
        d="M14.697 29.6959C22.8139 29.6959 29.394 23.0992 29.394 14.9617C29.394 6.82427 22.8139 0.227539 14.697 0.227539C6.58007 0.227539 0 6.82427 0 14.9617C0 23.0992 6.58007 29.6959 14.697 29.6959Z"
        fill={theme.texto}
      />
      <path
        opacity="0.7"
        d="M45.3032 29.6959C53.4201 29.6959 60.0002 23.0992 60.0002 14.9617C60.0002 6.82427 53.4201 0.227539 45.3032 0.227539C37.1863 0.227539 30.6062 6.82427 30.6062 14.9617C30.6062 23.0992 37.1863 29.6959 45.3032 29.6959Z"
        fill={theme.texto}
      />
      <path
        opacity="0.7"
        d="M14.697 60.2277C22.8139 60.2277 29.394 53.631 29.394 45.4935C29.394 37.356 22.8139 30.7593 14.697 30.7593C6.58007 30.7593 0 37.356 0 45.4935C0 53.631 6.58007 60.2277 14.697 60.2277Z"
        fill={theme.texto}
      />
      <path
        opacity="0.7"
        d="M45.3032 60.2277C53.4201 60.2277 60.0002 53.631 60.0002 45.4935C60.0002 37.356 53.4201 30.7593 45.3032 30.7593C37.1863 30.7593 30.6062 37.356 30.6062 45.4935C30.6062 53.631 37.1863 60.2277 45.3032 60.2277Z"
        fill={theme.texto}
      />
      <path
        opacity="0.7"
        d="M14.697 29.6959C22.8139 29.6959 29.394 23.0992 29.394 14.9617C29.394 6.82427 22.8139 0.227539 14.697 0.227539C6.58007 0.227539 0 6.82427 0 14.9617C0 23.0992 6.58007 29.6959 14.697 29.6959Z"
        fill={theme.texto}
      />
      <path
        opacity="0.7"
        d="M45.3032 29.6959C53.4201 29.6959 60.0002 23.0992 60.0002 14.9617C60.0002 6.82427 53.4201 0.227539 45.3032 0.227539C37.1863 0.227539 30.6062 6.82427 30.6062 14.9617C30.6062 23.0992 37.1863 29.6959 45.3032 29.6959Z"
        fill={theme.texto}
      />
    </SVG>
  );
}

const SVG = styled.svg`
  width: 60px;
  height: 60px;

  & path {
    transition: 0.2s;
  }

  ${breakpoints.min_desktop} {
    width: 122.97px;
    height: 122.689px;
  }
`;

export default Reliability;
